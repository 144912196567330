import "./table.scss";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from '../../firebase';
import { collection, doc, setDoc, addDoc, updateDoc, deleteDoc, getDoc, getDocs, where, query, orderBy, limit, startAfter } from "firebase/firestore";
import JSZip from 'jszip';
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
    getFolderName, downloadSelectedDocumentsAsPDF, capitalizeFirstLetter,
    downloadSelectedPhotos, formatDateForFolder, downloadSelectedCSV,
    isWithinLastNDays, formatDate, applyDateFilter, DownloadModal
} from './documentUtils';
import { uploadLogToFirestore, uploadErrorLogToFirestore } from '../../utils/logUtils.js'
import Bowser from "bowser";
import { ActionButtons, Table, FilterBar, DateFilterDropdown } from './ReportList.js'
import { fetchClientNames } from "./documentService.js"
import LoadAllButton from './utils/LoadAllButton';
import { Button, CircularProgress } from '@mui/material';


const PAGE_SIZE = 50;


/**
 * Componente List.
 * @returns {JSX.Element} - Elemento de React que representa la lista de documentos.
 */
const List = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSafari = browser.getBrowserName() === "Safari";

    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterDateRegistro, setFilterDateRegistro] = useState("");
    const [filterVigilante, setFilterVigilante] = useState("");
    const [documentsLoaded, setDocumentsLoaded] = useState(true);
    const [searchTienda, setSearchTienda] = useState("");
    const [nombresLoaded, setNombresLoaded] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [dateFilter, setDateFilter] = useState("all"); // "all" or "last3Days"
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadFeedback, setDownloadFeedback] = useState(null);
    const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [hasLoadedAll, setHasLoadedAll] = useState(false);

    // Nuevos estados para paginación
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const { tipVS } = useParams();
    const { currentUser, currentService, adminData } = useContext(AuthContext);

    // Añadir referencia para controlar el desmontaje
    const mounted = useRef(true);

    // Limpiar al desmontar
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    /**
  * Recupera y agrega el nombre del vigilante a cada documento.
  */
    const populateNombre = useCallback(async (rowsToProcess) => {
        if (!rowsToProcess || nombresLoaded) return rowsToProcess;
    
        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioId = servicioDoc.id;
    
                // Obtener solo los documentos que no tienen nombre y tienen vigilante
                const docsWithoutInfo = rowsToProcess.filter(doc => 
                    !doc.nombre && doc.vigilante
                );
    
                if (docsWithoutInfo.length === 0) {
                    setNombresLoaded(true);
                    return rowsToProcess;
                }
    
                // Obtener emails únicos de vigilantes
                const vigilanteEmails = [...new Set(docsWithoutInfo
                    .map(doc => doc.vigilante)
                    .filter(Boolean))];
    
                if (vigilanteEmails.length === 0) {
                    setNombresLoaded(true);
                    return rowsToProcess;
                }
    
                // Crear un mapa de vigilantes
                const vigilantesMap = new Map();
    
                // Obtener datos de vigilantes en un solo batch
                const vigilantesQuery = query(
                    collection(db, 'servicios', servicioId, 'vigilantes'),
                    where('email', 'in', vigilanteEmails)
                );
    
                const vigilantesSnapshot = await getDocs(vigilantesQuery);
                vigilantesSnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    vigilantesMap.set(data.email, {
                        nombre: `${data.nombre} ${data.apellidos}`,
                        email: data.email
                    });
                });
    
                // Actualizar documentos
                const updatedRows = rowsToProcess.map(doc => {
                    if (!doc.vigilante) return doc;
    
                    const vigilanteInfo = vigilantesMap.get(doc.vigilante);
    
                    if (!vigilanteInfo) return doc;
    
                    return {
                        ...doc,
                        nombre: vigilanteInfo.nombre,
                        vigilanteEmail: vigilanteInfo.email
                    };
                });
    
                setNombresLoaded(true);
                return updatedRows;
            }
            return rowsToProcess;
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
            uploadErrorLogToFirestore('Error en proceso de nombres:', error);
            return rowsToProcess;
        }
    }, [currentUser.email, nombresLoaded]);


    const fetchDocuments = useCallback(async (loadMore = false) => {
        if (!mounted.current) return;

        try {
            if (!currentService?.id || !adminData) {
                console.log('Waiting for data:', {
                    serviceId: currentService?.id,
                    hasAdminData: !!adminData,
                    adminData
                });
                return;
            }

            setIsLoading(true);

            const clientNames = await fetchClientNames(adminData.clientes, currentService);
            console.log('Client names:', clientNames);

            const documentsRef = collection(db, "servicios", currentService.id, "documents");

            // Consulta simple inicial para verificar documentos
            const testQuery = query(documentsRef, orderBy("fecha", "desc"), limit(1));
            const testSnapshot = await getDocs(testQuery);
            console.log('Test document:', testSnapshot.empty ? 'No documents' : testSnapshot.docs[0].data());

            // Consulta principal sin filtro de borrado inicialmente
            let q = query(
                documentsRef,
                orderBy("fecha", "desc"),
                limit(PAGE_SIZE)
            );

            if (loadMore && lastDoc) {
                q = query(
                    documentsRef,
                    orderBy("fecha", "desc"),
                    startAfter(lastDoc),
                    limit(PAGE_SIZE)
                );
            }

            const documentsSnapshot = await getDocs(q);
            console.log('Raw documents found:', documentsSnapshot.size);

            if (documentsSnapshot.empty) {
                if (mounted.current) {
                    setHasMore(false);
                    setIsLoading(false);
                }
                return;
            }

            if (mounted.current) {
                setLastDoc(documentsSnapshot.docs[documentsSnapshot.docs.length - 1]);
            }

            const documents = documentsSnapshot.docs
                .map((doc) => {
                    const documentData = doc.data();
                    console.log('Document data:', documentData);
                    const timestamp = documentData.fecha;
                    if (timestamp) {
                        const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
                        documentData.timestamp = date.getTime();
                    }
                    return { id: doc.id, ...documentData };
                })
                .filter(doc => {
                    const included = clientNames.includes(doc.cliente) && (!doc.borrado || doc.borrado === null);
                    if (!included) {
                        console.log('Filtered out:', { client: doc.cliente, borrado: doc.borrado });
                    }
                    return included;
                });

            console.log('Filtered documents:', documents.length);

            if (!mounted.current) return;

            const formattedDocuments = documents.map((document) => {
                const date = new Date(document.timestamp);
                return {
                    ...document,
                    fecha: date.toLocaleString('es-ES')
                };
            });

            const documentsWithNames = await populateNombre(formattedDocuments);

            if (mounted.current) {
                setRows(prevRows => loadMore ?
                    [...prevRows, ...documentsWithNames] :
                    documentsWithNames
                );
                setHasMore(documentsWithNames.length >= PAGE_SIZE);
            }

        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                code: error.code,
                stack: error.stack
            });
            if (mounted.current) {
                uploadErrorLogToFirestore('Error al obtener los documentos: ' + error);
            }
        } finally {
            if (mounted.current) {
                setIsLoading(false);
            }
        }
    }, [currentService?.id, adminData, lastDoc, populateNombre]);


    /**
 * Recupera los documentos del servicio actual.
 */
    useEffect(() => {
        const fetchData = async () => {
            if (!rows.some(row => row.nombre)) {
                setNombresLoaded(false);
            }
            if (rows.length > 0 && !nombresLoaded) {
                await populateNombre();
            }
        };

        fetchData();
    }, [rows, nombresLoaded, populateNombre]);

    // Esperar a que adminData esté disponible
    useEffect(() => {
        if (currentService?.id && adminData) {
            fetchDocuments();
        }
    }, [currentService?.id, adminData]);


    useEffect(() => {
        if (mounted.current) {
            setLastDoc(null);
            setHasMore(true);
            setRows([]);
            fetchDocuments(false);
        }
    }, [tipVS]);

    /**
 * Maneja el cambio en el campo de búsqueda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de búsqueda.
 */
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    /**
 * Maneja el cambio en la selección de todas las filas.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de la selección de todas las filas.
 */
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            // Marcar todas las filas como seleccionadas
            const allRowsIds = filteredRows.map((row) => row.id);
            setSelectedRows(allRowsIds);
            setAllRowsSelected(true);
        } else {
            // Desmarcar todas las filas
            setSelectedRows([]);
            setAllRowsSelected(false);
        }
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de subida.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de subida.
 */
    const handleFilterChange = (event) => {
        setFilterDate(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de suceso.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de suceso.
 */
    const handleFilterChangeDate = (event) => {
        setFilterDateRegistro(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de vigilante.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de vigilante.
 */
    const handleVigilanteFilterChange = (event) => {
        setFilterVigilante(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de tienda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de tienda.
 */
    const handleTiendaFilterChange = (event) => {
        setSearchTienda(event.target.value);
    };

    /**
 * Maneja la selección/deselección de una fila.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de selección de la fila.
 * @param {string} id - ID de la fila seleccionada/deseleccionada.
 */
    const handleRowSelection = (event, id) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        }

        // Verificar si la fila se ha marcado como borrada
        const isDeleted = event.target.checked && deletedRows.includes(id);

        // Si la fila está marcada como borrada, eliminarla de la lista de borrados
        if (isDeleted) {
            setDeletedRows(deletedRows.filter((rowId) => rowId !== id));
        }
    };


    /**
    * Maneja la descarga de los documentos seleccionados.
    */
    /**
  * Handles the download of selected documents with improved error handling
  * @returns {Promise<void>}
  */
    const handleDownloadSelected = async () => {
        try {
            // Initialize download state
            setDownloadProgress(0);
            setDownloadFeedback("Iniciando descarga...");
            setDownloadModalOpen(true);

            if (!currentService?.id) {
                throw new Error("No se encontró el ID del servicio");
            }

            const selectedDocuments = rows.filter((row) => selectedRows.includes(row.id));
            if (selectedDocuments.length === 0) {
                throw new Error("No hay documentos seleccionados");
            }

            const zip = new JSZip();
            const folder = zip.folder('documentos');
            if (!folder) {
                throw new Error("No se pudo crear la carpeta ZIP");
            }

            const totalFiles = selectedDocuments.length;
            let downloadedFiles = 0;

            // Process each document
            // Process each document
            for (const document of selectedDocuments) {
                try {
                    setDownloadFeedback(`Procesando documento ${downloadedFiles + 1} de ${totalFiles}`);
                    console.log("Processing document:", {
                        id: document.id,
                        type: document.documentTypeId,
                        fields: document.fields
                    });

                    let isCustomForm = true;

                    try {
                        const formRef = doc(db, 'servicios', currentService.id, 'forms', document.documentTypeId);
                        console.log("Checking form reference:", formRef.path);

                        const formSnapshot = await getDoc(formRef);
                        console.log("Form snapshot exists:", formSnapshot.exists());
                        console.log("Form data:", formSnapshot.data());

                        if (formSnapshot.exists()) {
                            const formData = formSnapshot.data();
                            console.log("Form export types:", formData.export);

                            // Modificación aquí: verificar si export existe y manejarlo como string o array
                            if (formData.export) {
                                // Convertir a array si es string
                                const exportTypes = Array.isArray(formData.export) ? formData.export : [formData.export];
                                console.log("Processing export types:", exportTypes);

                                for (const exportType of exportTypes) {
                                    console.log("Processing export type:", exportType);

                                    try {
                                        switch (exportType) {
                                            case "pdf":
                                                console.log("Starting PDF generation for:", document.id);
                                                await downloadSelectedDocumentsAsPDF([document], folder, exportType);
                                                console.log("PDF generation completed for:", document.id);
                                                break;
                                            case "csv":
                                                console.log("Starting CSV generation for:", document.id);
                                                await downloadSelectedCSV([document], folder, exportType);
                                                console.log("CSV generation completed for:", document.id);
                                                break;
                                            case "foto":
                                                console.log("Starting photo processing for:", document.id);
                                                await downloadSelectedPhotos([document], folder, exportType);
                                                console.log("Photo processing completed for:", document.id);
                                                break;
                                            default:
                                                console.log("Unknown export type:", exportType);
                                        }
                                    } catch (exportError) {
                                        console.error(`Error processing ${exportType} for document ${document.id}:`, exportError);
                                        uploadErrorLogToFirestore(`Error procesando ${exportType} para documento ${document.id}: ${exportError.message}`);
                                    }
                                }
                            } else {
                                console.log("No export type defined in form data");
                                isCustomForm = false;
                            }
                        } else {
                            console.log("No form definition found, treating as standard document");
                            isCustomForm = false;
                        }
                    } catch (formError) {
                        console.error("Error checking form definition:", formError);
                        isCustomForm = false;
                    }

                    // Si no es un formulario personalizado o no se encontraron tipos de exportación
                    if (!isCustomForm) {
                        console.log("Processing as standard document type");

                        if (document.documentTypeId === "112") {
                            await downloadSelectedCSV([document], folder);
                        }

                        if (["legal", "ims", "denuncia", "intervencion"].includes(document.documentTypeId)) {
                            await downloadSelectedPhotos([document], folder);
                        }

                        if (["parte de trabajo", "intervencion", "112", "parte_de_trabajo"].includes(document.documentTypeId)) {
                            await downloadSelectedDocumentsAsPDF([document], folder);
                        }
                    }

                    // Actualizar progreso
                    downloadedFiles++;
                    setDownloadProgress((downloadedFiles / totalFiles) * 100);

                } catch (error) {
                    console.error(`Error processing document ${document.id}:`, error);
                    uploadErrorLogToFirestore(`Error en documento ${document.id}: ${error.message}`);
                }
            }


            // Generate and download ZIP
            setDownloadFeedback("Generando archivo ZIP...");
            const content = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 6
                }
            });

            // Create download link
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = "documentos.zip";

            // Trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

            // Update document status in database
            setDownloadFeedback("Actualizando estado de documentos...");
            const currentTimeStamp = new Date();

            await Promise.all(selectedDocuments.map(async (document) => {
                try {
                    const docRef = doc(db, 'servicios', currentService.id, 'documents', document.id);
                    await updateDoc(docRef, {
                        downloaded: true,
                        downloadedTime: currentTimeStamp
                    });
                } catch (error) {
                    console.error(`Error updating document status ${document.id}:`, error);
                    uploadErrorLogToFirestore(`Error actualizando estado de documento ${document.id}: ${error.message}`);
                }
            }));

            // Update UI state
            setRows(rows.map(row => {
                if (selectedRows.includes(row.id)) {
                    return { ...row, downloaded: true, downloadedTime: currentTimeStamp };
                }
                return row;
            }));

            setSelectedRows([]);
            setDownloadFeedback("Descarga completada con éxito");
            uploadLogToFirestore('Documentos descargados exitosamente');

        } catch (error) {
            console.error("Error en la descarga:", error);
            setDownloadFeedback(`Error: ${error.message}`);
            uploadErrorLogToFirestore(`Error general en la descarga: ${error.message}`);
        } finally {
            // Reset progress after 3 seconds
            setTimeout(() => {
                setDownloadProgress(0);
                if (downloadFeedback.includes("éxito")) {
                    setDownloadModalOpen(false);
                }
            }, 3000);
        }
    };
    const handleLoadAll = async () => {
        if (!currentService?.id || !adminData || isLoadingAll) return;

        setIsLoadingAll(true);

        try {
            let allDocuments = [];
            let lastDocRef = null;
            let hasMoreDocs = true;

            while (hasMoreDocs) {
                const documentsRef = collection(db, "servicios", currentService.id, "documents");
                let q = query(
                    documentsRef,
                    orderBy("fecha", "desc"),
                    limit(500)
                );

                if (lastDocRef) {
                    q = query(
                        documentsRef,
                        orderBy("fecha", "desc"),
                        startAfter(lastDocRef),
                        limit(500)
                    );
                }

                const documentsSnapshot = await getDocs(q);

                if (documentsSnapshot.empty) {
                    hasMoreDocs = false;
                    break;
                }

                const newDocs = documentsSnapshot.docs
                    .map((doc) => {
                        const documentData = doc.data();
                        const timestamp = documentData.fecha;
                        if (timestamp) {
                            const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
                            documentData.timestamp = date.getTime();
                        }
                        return {
                            id: doc.id,
                            ...documentData,
                            fecha: new Date(documentData.timestamp).toLocaleString('es-ES')
                        };
                    })
                    .filter(doc => !doc.borrado || doc.borrado === null);

                lastDocRef = documentsSnapshot.docs[documentsSnapshot.docs.length - 1];
                allDocuments = [...allDocuments, ...newDocs];
            }

            const documentsWithNames = await populateNombre(allDocuments);

            // Actualizar el estado de manera segura
            setRows(prevRows => {
                const uniqueDocs = [...prevRows];
                documentsWithNames.forEach(doc => {
                    if (!uniqueDocs.find(existing => existing.id === doc.id)) {
                        uniqueDocs.push(doc);
                    }
                });
                return uniqueDocs;
            });

            setHasMore(false);
            setHasLoadedAll(true);

        } catch (error) {
            console.error('Error loading all documents:', error);
            uploadErrorLogToFirestore('Error al cargar todos los documentos: ' + error);
        } finally {
            setIsLoadingAll(false);
        }
    };

    const handleDeleteSelected = async () => {
        const currentTimeStamp = new Date();
        // Establecer el campo "borrado" en true para las filas seleccionadas
        const updatedDocuments = rows.map((row) => {
            if (selectedRows.includes(row.id)) {
                return {
                    ...row,
                    borrado: true,
                    borradoTime: currentTimeStamp,
                };
            }
            return row;
        });

        // Actualizar las filas en el estado
        setRows(updatedDocuments);

        // Actualizar la lista de filas borradas
        setDeletedRows([...deletedRows, ...selectedRows]);

        // Desmarcar todas las filas seleccionadas
        setSelectedRows([]);

        // Obtener el servicioId
        const servicioId = await fetchDocuments();

        // Actualizar los documentos en la base de datos
        const serviciosRef = collection(db, 'servicios');
        for (const document of updatedDocuments) {
            if (selectedRows.includes(document.id)) {
                await updateDoc(doc(serviciosRef, servicioId, 'documents', document.id), {
                    borrado: true,
                    borradoTime: currentTimeStamp,
                });
            }
        }
    };


    /**
     * Filtra las filas de documentos según los términos de búsqueda y los filtros seleccionados.
     * @returns {Array} - Filas de documentos filtrados.
     */
    const filteredRows = useMemo(() => {
        return rows.filter((row) => {
            const fecha = row.fields?.FECHA ?? '';
            const nombre = row.nombre ?? '';
            const documentDate = formatDate(row.fecha);

            return (
                row.documentTypeId?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                row.fecha?.toLowerCase().includes(filterDate.toLowerCase()) &&
                fecha.toLowerCase().includes(filterDateRegistro.toLowerCase()) &&
                (!row.tienda || row.tienda?.toLowerCase().includes(searchTienda.toLowerCase())) &&
                nombre.toLowerCase().includes(filterVigilante.toLowerCase()) &&
                !deletedRows.includes(row.id) &&
                applyDateFilter(row, documentDate, dateFilter)
            );
        });
    }, [rows, searchTerm, filterDate, filterDateRegistro, searchTienda, filterVigilante, deletedRows, dateFilter]);

    const handleLoadMore = useCallback(async () => {
        if (!isLoading && hasMore) {
            console.log('Loading more...'); // Debug
            await fetchDocuments(true);
        }
    }, [isLoading, hasMore, fetchDocuments]);

    // Dentro del componente Table, añade esto al inicio
    console.log('Table props:', {
        rowsCount: filteredRows.length,
        selectedCount: selectedRows.length,
        allSelected: allRowsSelected
    });

    // Y en el return del List, añade esto antes del Table
    console.log('Rendering List with rows:', rows.length);

    return (
        <div className="tableContainer">
            <h2 className="datatableTitle">Reportes</h2>
            <FilterBar
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filterDate={filterDate}
                handleFilterChange={handleFilterChange}
                filterDateRegistro={filterDateRegistro}
                handleFilterChangeDate={handleFilterChangeDate}
                searchTienda={searchTienda}
                handleTiendaFilterChange={handleTiendaFilterChange}
                filterVigilante={filterVigilante}
                handleVigilanteFilterChange={handleVigilanteFilterChange}
            />
            <DownloadModal
                isOpen={isDownloadModalOpen}
                onClose={() => setDownloadModalOpen(false)}
                progress={downloadProgress}
                feedback={downloadFeedback}
            />
            <div className="tableControlsRow">
                <ActionButtons
                    handleDownloadSelected={handleDownloadSelected}
                    selectedRows={selectedRows}
                    handleDeleteSelected={handleDeleteSelected}
                />
                <DateFilterDropdown dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </div>

            {isLoading ? (
                <div className="loading">Cargando...</div>
            ) : (
                <>
                    <Table
                        filteredRows={filteredRows}
                        allRowsSelected={allRowsSelected}
                        selectedRows={selectedRows}
                        handleSelectAllRows={handleSelectAllRows}
                        handleRowSelection={handleRowSelection}
                        handleDeleteSelected={handleDeleteSelected}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
                        {hasMore && (
                            <Button
                                onClick={handleLoadMore}
                                variant="contained"
                                color="primary"
                            >
                                Cargar más
                            </Button>
                        )}
                        <Button
                            onClick={handleLoadAll}
                            variant="contained"
                            color="secondary"
                            disabled={isLoadingAll}
                        >
                            {isLoadingAll ? (
                                <>
                                    <CircularProgress
                                        size={20}
                                        style={{ marginRight: '8px' }}
                                        color="inherit"
                                    />
                                    Cargando todos...
                                </>
                            ) : (
                                'Mostrar todos'
                            )}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );

};


export default List;