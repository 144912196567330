import {
    collection,
    doc,
    serverTimestamp,
    setDoc,
    getDocs,
    query,
    where,
    updateDoc
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const fetchTiendas = async (email) => {
    let list = [];
    try {
        const serviciosRef = collection(db, "servicios");
        const q = query(serviciosRef, where("admins", "array-contains", email));
        const serviciosSnapshot = await getDocs(q);
        for (const servicioDoc of serviciosSnapshot.docs) {
            const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));
            tiendasSnapshot.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
        }
        list.sort((a, b) => a.numTienda - b.numTienda);
    } catch (err) {
        console.log(err);
    }
    return list;
};

export const fetchClientes = async (email) => {
    const serviciosRef = collection(db, "servicios");
    const q = query(serviciosRef, where("admins", "array-contains", email));
    const serviciosSnapshot = await getDocs(q);
    const listaClientes = [];
    for (const servicioDoc of serviciosSnapshot.docs) {
        const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));
        tiendasSnapshot.forEach((doc) => {
            const tiendaData = doc.data();
            const nombreCliente = tiendaData.nombreCliente;

            if (!listaClientes.includes(nombreCliente)) {
                listaClientes.push(nombreCliente);
            }
        });
    }
    return listaClientes;
};

export const registerVigilante = async (data, email) => {
    try {
        const servicioRef = collection(db, "servicios");
        const querySnapshot = await getDocs(query(
            servicioRef,
            where("admins", "array-contains", email)
        ));
        const servicioDoc = querySnapshot.docs[0];
        const vigilantesRef = collection(
            doc(servicioRef, servicioDoc.id),
            "vigilantes"
        );
        console.log('load data', data)
        
        // Usar el email proporcionado en lugar de tipVS
        const res = await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.dni
        );

        const servicioData = servicioDoc.data();
        if (!servicioData.users) {
            await setDoc(servicioDoc.ref, { users: [] });
        }

        // Usar el email en lugar de tipVS para el array de users
        const userEmail = data.email.toLowerCase();
        await updateDoc(servicioDoc.ref, {
            users: [userEmail, ...servicioData.users],
        });

        await setDoc(doc(vigilantesRef, res.user.uid), {
            ...data,
            borrado: false,
            timeStamp: serverTimestamp(),
        });

        return { success: true, uid: res.user.uid };
    } catch (err) {
        console.log(err);
        return { success: false, error: err.message };
    }
};

// Extraer inspectores asignados desde la subcolección "admins" dentro del servicio actual
export const fetchInspectoresAsignados = async (currentServiceId) => {
    const inspectoresList = [];
    try {
        const adminsRef = collection(db, "servicios", currentServiceId, "admins");
        const inspectoresQuery = query(adminsRef, where("tipoUsuario", "array-contains", "inspector"));
        const inspectoresSnapshot = await getDocs(inspectoresQuery);

        inspectoresSnapshot.forEach((doc) => {
            const inspectorData = doc.data();
            inspectoresList.push({ id: doc.id, ...inspectorData });
        });
    } catch (err) {
        console.log(err);
    }
    return inspectoresList;
};

// Extraer administrativos asignados desde la subcolección "admins" dentro del servicio actual
export const fetchAdministrativosAsignados = async (currentServiceId) => {
    const administrativosList = [];
    try {
        const adminsRef = collection(db, "servicios", currentServiceId, "admins");
        const administrativosQuery = query(adminsRef, where("tipoUsuario", "array-contains", "administrativo"));
        const administrativosSnapshot = await getDocs(administrativosQuery);

        administrativosSnapshot.forEach((doc) => {
            const administrativoData = doc.data();
            administrativosList.push({ id: doc.id, ...administrativoData });
        });
    } catch (err) {
        console.log(err);
    }
    return administrativosList;
};
