import Papa from 'papaparse';
import { obtenerTurnosPorCuadrante, obtenerVigilantePorId, agregarTurno, generarDatosExportacion } from "./FirestoreCuadrantes";
import { obtenerDetallesServicio } from './FirestoreCuadrantes';


const createTimestamp = (fecha, hora) => {
  try {
    // Validar que no sean strings vacíos o undefined
    if (!fecha?.trim() || !hora?.trim()) {
      console.log('Empty date or time strings:', { fecha, hora });
      return null;
    }

    const [year, month, day] = fecha.split('-').map(s => s.trim());
    const [hours, minutes] = hora.split(':').map(s => s.trim());
    
    // Validar que todos los componentes sean números válidos
    if (!year || !month || !day || !hours || !minutes) {
      console.log('Invalid date/time components:', { year, month, day, hours, minutes });
      return null;
    }

    const timestamp = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes)
    );

    // Validar que la fecha resultante sea válida
    if (isNaN(timestamp.getTime())) {
      console.log('Invalid timestamp created:', timestamp);
      return null;
    }

    return timestamp;
  } catch (error) {
    console.error('Error creating timestamp:', { fecha, hora }, error);
    return null;
  }
};

const importarCuadrante = async (data, cuadranteId, currentService, setEvents) => {
  try {
    console.log('Datos recibidos para importar:', data);
    
    if (!cuadranteId) {
      throw new Error('No hay un cuadrante seleccionado');
    }

    // Encontrar pares de filas HINI/HFIN
    const rowPairs = [];
    for (let i = 0; i < data.length; i += 2) {
      const hiniRow = data[i];
      const hfinRow = data[i + 1];

      if (hiniRow?.['TIPO HORARIO'] === 'HINI' && hfinRow?.['TIPO HORARIO'] === 'HFIN' &&
          hiniRow?.['COD. PERSONA'] === hfinRow?.['COD. PERSONA']) {
        rowPairs.push({ hini: hiniRow, hfin: hfinRow });
      }
    }

    console.log('Pares de filas encontrados:', rowPairs);

    let turnosCreados = 0;

    for (const pair of rowPairs) {
      const { hini, hfin } = pair;
      const vigilante = hini['COD. PERSONA'];
      const turno = hini['TURNO'];
      const nombrePersona = hini['NOMBRE PERSONA'];

      // Procesar cada día del mes
      for (let dia = 1; dia <= 31; dia++) {
        const diaStr = dia.toString();
        const horaInicio = hini[diaStr]?.trim();
        const horaFin = hfin[diaStr]?.trim();

        if (horaInicio && horaFin) {
          const fecha = `${hini['CALENDARIO']}-${hini['MES'].padStart(2, '0')}-${diaStr.padStart(2, '0')}`;
          console.log(`Procesando turno para fecha ${fecha}:`, {
            vigilante,
            horaInicio,
            horaFin,
            turno
          });

          const turnoData = {
            fecha,
            inicioTurno: horaInicio,
            finTurno: horaFin,
            vigilante,
            persona: nombrePersona,
            observaciones: `Turno importado - ${turno}`,
            turno,
            arcos: false,
            manejoExplosivos: false,
            peligrosidad: false,
            conArma: false
          };

          try {
            await agregarTurno(cuadranteId, turnoData, currentService);
            turnosCreados++;
            console.log(`Turno creado exitosamente para ${fecha}`);
          } catch (error) {
            console.error(`Error creando turno para ${fecha}:`, error);
          }
        }
      }
    }

    console.log(`Total de turnos creados: ${turnosCreados}`);

    // Actualizar los eventos
    const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);
    console.log('Turnos obtenidos después de importación:', turnos);

    const eventos = await Promise.all(
      turnos.map(async (turno) => {
        if (turno.vigilante) {
          try {
            const vigilanteData = await obtenerVigilantePorId(turno.vigilante, currentService);
            
            const [year, month, day] = turno.fecha.split('-');
            const [horaInicio, minutosInicio = '00'] = turno.inicioTurno.split(':');
            const [horaFin, minutosFin = '00'] = turno.finTurno.split(':');

            const start = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 
                                 parseInt(horaInicio), parseInt(minutosInicio));
            const end = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 
                               parseInt(horaFin), parseInt(minutosFin));

            if (end < start) {
              end.setDate(end.getDate() + 1);
            }

            return {
              id: turno.id,
              title: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : turno.vigilante,
              start,
              end,
              fecha: turno.fecha,
              inicioTurno: turno.inicioTurno,
              finTurno: turno.finTurno,
              vigilante: turno.vigilante,
              turno: turno.turno,
              persona: turno.persona
            };
          } catch (error) {
            console.error('Error procesando turno:', error);
            return null;
          }
        }
        return null;
      })
    );

    const eventosValidos = eventos.filter(evento => evento !== null);
    console.log('Eventos válidos generados:', eventosValidos);
    
    setEvents(eventosValidos);
    alert(`Importación completada. Se crearon ${turnosCreados} turnos.`);

  } catch (error) {
    console.error('Error en la importación:', error);
    throw error;
  }
};


export const handleFileUpload = async (event, cuadranteId, currentService, setIsImporting, setImportError, setEvents) => {
  try {
    console.log('Start of handleFileUpload');
    const file = event.target.files[0];
    console.log("File selected:", file);

    if (!file) {
      console.log('No file selected');
      setImportError('No se seleccionó ningún archivo');
      return;
    }

    if (!cuadranteId) {
      console.log('No cuadranteId provided');
      setImportError('No hay un cuadrante seleccionado');
      return;
    }

    setIsImporting(true);
    setImportError(null);

    // Read file content
    const reader = new FileReader();
    
    reader.onload = async (e) => {
      console.log('File loaded');
      const text = e.target.result;
      console.log('File content (first 200 chars):', text.substring(0, 200));

      // Basic CSV parsing
      Papa.parse(text, {
        delimiter: '\t', // Tab delimiter
        header: true,
        encoding: "UTF-8",
        skipEmptyLines: true,
        complete: async (results) => {
          console.log('Parsing complete');
          console.log('Headers:', results.meta.fields);
          console.log('First row:', results.data[0]);
          console.log('Total rows:', results.data.length);

          try {
            // Process the data
            const processedRows = [];
            for (let i = 0; i < results.data.length; i += 2) {
              const hiniRow = results.data[i];
              const hfinRow = results.data[i + 1];

              // Basic validation
              if (!hiniRow || !hfinRow) {
                console.log('Invalid row pair:', { hiniRow, hfinRow });
                continue;
              }

              console.log('Processing row pair:', {
                hini: hiniRow['TIPO HORARIO'],
                hfin: hfinRow['TIPO HORARIO'],
                codPersona: hiniRow['COD. PERSONA']
              });

              if (hiniRow['TIPO HORARIO'] === 'HINI' && 
                  hfinRow['TIPO HORARIO'] === 'HFIN' &&
                  hiniRow['COD. PERSONA'] === hfinRow['COD. PERSONA']) {
                processedRows.push({ hini: hiniRow, hfin: hfinRow });
              }
            }

            console.log('Processed rows:', processedRows.length);

            // Create turnos
            let turnosCreados = 0;
            for (const pair of processedRows) {
              for (let dia = 1; dia <= 31; dia++) {
                const diaStr = dia.toString();
                const horaInicio = pair.hini[diaStr]?.trim();
                const horaFin = pair.hfin[diaStr]?.trim();

                if (horaInicio && horaFin) {
                  const turnoData = {
                    fecha: `${pair.hini['CALENDARIO']}-${pair.hini['MES'].padStart(2, '0')}-${diaStr.padStart(2, '0')}`,
                    inicioTurno: horaInicio,
                    finTurno: horaFin,
                    vigilante: pair.hini['COD. PERSONA'],
                    persona: pair.hini['NOMBRE PERSONA'],
                    turno: pair.hini['TURNO'],
                    observaciones: 'Turno importado',
                    arcos: false,
                    manejoExplosivos: false,
                    peligrosidad: false,
                    conArma: false
                  };

                  console.log('Creating turno:', turnoData);
                  await agregarTurno(cuadranteId, turnoData, currentService);
                  turnosCreados++;
                }
              }
            }

            console.log('Total turnos created:', turnosCreados);

            // Update events
            const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);
            const eventos = await Promise.all(turnos.map(async (turno) => {
              if (!turno.vigilante) return null;

              const [year, month, day] = turno.fecha.split('-');
              const [horaInicio] = turno.inicioTurno.split(':');
              const [horaFin] = turno.finTurno.split(':');

              const start = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(horaInicio));
              const end = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(horaFin));

              if (parseInt(horaFin) < parseInt(horaInicio)) {
                end.setDate(end.getDate() + 1);
              }

              return {
                id: turno.id,
                title: turno.persona || turno.vigilante,
                start: start,
                end: end,
                fecha: turno.fecha,
                inicioTurno: turno.inicioTurno,
                finTurno: turno.finTurno,
                vigilante: turno.vigilante,
                turno: turno.turno,
                persona: turno.persona
              };
            }));

            const eventosValidos = eventos.filter(e => e !== null);
            console.log('Valid events created:', eventosValidos.length);
            
            setEvents(eventosValidos);
            setIsImporting(false);
            alert(`Importación completada. Se crearon ${turnosCreados} turnos.`);

          } catch (error) {
            console.error('Processing error:', error);
            setImportError(`Error procesando datos: ${error.message}`);
            setIsImporting(false);
          }
        },
        error: (error) => {
          console.error('Parse error:', error);
          setImportError(`Error al parsear el archivo: ${error.message}`);
          setIsImporting(false);
        }
      });
    };

    reader.onerror = (error) => {
      console.error('Read error:', error);
      setImportError(`Error leyendo el archivo: ${error}`);
      setIsImporting(false);
    };

    console.log('Starting file read');
    reader.readAsText(file, 'UTF-8');

  } catch (error) {
    console.error('General error:', error);
    setImportError(`Error en la carga del archivo: ${error.message}`);
    setIsImporting(false);
  }
};



export const exportarCuadrante = async (events, mesExportacion, currentService, selectedServicios, descContrato) => {
  try {
    console.log('Starting export with events:', events);

    // Obtener detalles del servicio
    if (!selectedServicios || selectedServicios.length === 0) {
      throw new Error('No hay servicios seleccionados');
    }

    const servicioId = selectedServicios[0];
    const servicioDetails = await obtenerDetallesServicio(servicioId, currentService);
    console.log('Detalles del servicio obtenidos:', servicioDetails);

    // Agrupar por vigilante primero
    const vigilantesData = {};
    
    // Primero, obtener datos de vigilantes
    for (const event of events) {
      if (!event.vigilante) continue;

      try {
        if (!vigilantesData[event.vigilante]) {
          // Obtener datos del vigilante si no los tenemos ya
          const vigilanteData = await obtenerVigilantePorId(event.vigilante, currentService);
          
          vigilantesData[event.vigilante] = {
            codPersona: event.vigilante,
            nombre: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : event.title,
            turno: event.turno || '1',
            dias: Array(31).fill({ inicio: '', fin: '' })
          };
        }

        // Agregar los horarios al día correspondiente
        const [_, __, dia] = event.fecha.split('-');
        const diaIndex = parseInt(dia) - 1;
        vigilantesData[event.vigilante].dias[diaIndex] = {
          inicio: event.inicioTurno,
          fin: event.finTurno
        };
      } catch (error) {
        console.error('Error procesando vigilante:', error);
      }
    }

    console.log('Datos agrupados por vigilante:', vigilantesData);

    // Crear las filas de exportación
    const filasExport = [];
    
    for (const [vigilanteId, datos] of Object.entries(vigilantesData)) {
      const baseRow = {
        'EMPRESA': servicioDetails.empresa,
        'DELEGACIÓN': servicioDetails.delegacion,
        'CALENDARIO': mesExportacion.getFullYear().toString(),
        'MES': (mesExportacion.getMonth() + 1).toString().padStart(2, '0'),
        'CONTRATO': servicioDetails.contrato,
        'DESC. CONTRATO': descContrato,
        'SERVICIO': servicioDetails.servicio,
        'DESC. SERVICIO': servicioDetails.descripcionServicio,
        'EMP. PERSONA': servicioDetails.empPersona,
        'DELEG. PERSONA': servicioDetails.delgPersona,
        'COD. PERSONA': datos.codPersona,
        'NOMBRE PERSONA': datos.nombre,
        'TURNO': datos.turno
      };

      // Crear fila HINI
      const filaHINI = { ...baseRow, 'TIPO HORARIO': 'HINI' };
      datos.dias.forEach((dia, index) => {
        filaHINI[(index + 1).toString()] = dia.inicio || '';
      });
      filasExport.push(filaHINI);

      // Crear fila HFIN
      const filaHFIN = { ...baseRow, 'TIPO HORARIO': 'HFIN' };
      datos.dias.forEach((dia, index) => {
        filaHFIN[(index + 1).toString()] = dia.fin || '';
      });
      filasExport.push(filaHFIN);
    }

    if (filasExport.length === 0) {
      throw new Error('No hay datos para exportar');
    }

    // Definir columnas en orden
    const columnOrder = [
      'EMPRESA', 'DELEGACIÓN', 'CALENDARIO', 'MES', 'CONTRATO',
      'DESC. CONTRATO', 'SERVICIO', 'DESC. SERVICIO', 'EMP. PERSONA',
      'DELEG. PERSONA', 'COD. PERSONA', 'NOMBRE PERSONA', 'TURNO',
      'TIPO HORARIO'
    ];

    // Agregar columnas de días
    for (let i = 1; i <= 31; i++) {
      columnOrder.push(i.toString());
    }

    // Configurar CSV
    const csvConfig = {
      delimiter: '\t',
      header: true,
      columns: columnOrder,
      quotes: false,
      skipEmptyLines: true
    };

    // Generar CSV
    const csv = Papa.unparse(filasExport, csvConfig);
    const csvContent = "\uFEFF" + csv;
    
    // Crear y descargar archivo
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `cuadrante_${currentService.id}_${(mesExportacion.getMonth() + 1).toString().padStart(2, '0')}_${mesExportacion.getFullYear()}.csv`;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

  } catch (error) {
    console.error('Error al exportar cuadrante:', error);
    throw error;
  }
};