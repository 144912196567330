import "./DataCuadrantes.scss";
import { useEffect, useState, useContext } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import { fetchClientesPorAdministrativo, fetchClientesPorInspector, fetchUserData, fetchTiendasPorCampoCliente, } from "../../components/admin/firestoreService"
import { fetchTiendas, registerVigilante } from "../../pages/new/utils/FirebaseUtils"
import { generarDatosExportacion, createCuadrante, agregarTurno, obtenerTurnosPorCuadrante, obtenerVigilantePorId, obtenerDetallesServicio, obtenerDetallesServicios } from "./FirestoreCuadrantes";
import CreateTurno from './CreateTurno';
import ActualizarTurno from "./Actualizarturno";
import GridView from "./GridView";
import { handleFileUpload, exportarCuadrante } from './cuadrante-utils';
import ExportModal from './ExportModal';

const DataCuadrantes = () => {
  const [clientes, setClientes] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [userData, setUserData] = useState({});
  const [clienteSeleccionado, setClienteSeleccionado] = useState('');
  const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
  const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
  const [serviciosFiltrados, setServiciosFiltrados] = useState([]);
  const [events, setEvents] = useState([]);
  const [mostrarFormularioTurno, setMostrarFormularioTurno] = useState(false);
  const [cuadranteId, setCuadranteId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [turnoId, setTurnoId] = useState('');
  const [eventoTurnoSeleccionado, setEventoTurnoSeleccionado] = useState(null);
  const [isGridView, setIsGridView] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState(null);
  const [mesExportacion, setMesExportacion] = useState(new Date());
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const { currentService, currentUser, adminData } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser && currentService) {
          fetchUserData(currentUser, currentService)
            .then(setUserData)
            .catch(console.error);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentUser, currentService]); // Dependencias: currentUser y currentService

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        if (userData) {
          const clientesData = await fetchClientesPorInspector(userData.id, currentService);
          setClientes(clientesData);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, [userData, currentService]); // Dependencias: userData y currentService

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        if (currentUser) {
          const serviciosData = await fetchTiendas(currentUser.email);
          setServicios(serviciosData);
          console.log("servicios data", serviciosData);
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };

    fetchServiceData();
  }, [currentUser]); // Dependencia: currentUser

  useEffect(() => {
    if (clienteSeleccionado && currentService) {
      fetchTiendasPorCampoCliente(clienteSeleccionado, currentService)
        .then(setServicios) // Actualiza el estado con las tiendas filtradas
        .catch(console.error);
    }
  }, [clienteSeleccionado, currentService]);

 // En DataCuadrantes.js, dentro del useEffect que procesa los turnos:

useEffect(() => {
  const fetchTurnosCuadrante = async () => {
    try {
      if (cuadranteId) {
        const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);
        console.log("Turnos obtenidos de BD:", turnos);

        const eventos = await Promise.all(
          turnos.map(async (turno) => {
            if (turno.vigilante) {
              try {
                const vigilanteData = await obtenerVigilantePorId(turno.vigilante, currentService);
                console.log("Datos del vigilante:", vigilanteData);

                // Crear fechas correctamente
                const [year, month, day] = turno.fecha.split('-');
                const [horaInicio] = turno.inicioTurno.split(':');
                const [horaFin] = turno.finTurno.split(':');

                const start = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(horaInicio));
                const end = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(horaFin));

                if (parseInt(horaFin) < parseInt(horaInicio)) {
                  end.setDate(end.getDate() + 1);
                }

                return {
                  id: turno.id,
                  title: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : 'Sin vigilante',
                  start: start,
                  end: end,
                  turno: turno.turno || 'Sin turno',
                  vigilante: turno.vigilante,
                  vigilanteId: turno.vigilanteId,
                  fecha: turno.fecha,
                  inicioTurno: turno.inicioTurno,
                  finTurno: turno.finTurno,
                  persona: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : turno.vigilante
                };
              } catch (error) {
                console.error("Error procesando turno:", turno, error);
                return null;
              }
            }
            return null;
          })
        );

        const eventosValidos = eventos.filter(evento => evento !== null);
        console.log("Eventos finales:", eventosValidos);
        setEvents(eventosValidos);
      }
    } catch (error) {
      console.error('Error al obtener los turnos del cuadrante:', error);
    }
  };

  fetchTurnosCuadrante();
}, [cuadranteId, currentService]);

  const handleFileUploadWrapper = (event) => {
    handleFileUpload(event, cuadranteId, currentService, setIsImporting, setImportError, setEvents);
  };

  const handleExportarCuadrante = () => {
    setExportModalOpen(true);
  };

  const handleExportWithDesc = async (descContrato) => {
    try {
      await exportarCuadrante(events, mesExportacion, currentService, selectedServicios, descContrato);
      setExportModalOpen(false);
    } catch (error) {
      alert('Error al exportar el cuadrante');
    }
  };

  const handleMesChange = (nuevaFecha) => {
    setMesExportacion(nuevaFecha);
  };

  const handleClienteChange = (event) => {
    setSelectedClientes(event.target.value);

    // Filtrar y establecer las tiendas basadas en los clientes seleccionados
    const nuevasTiendasFiltradas = servicios.filter(servicio =>
      event.target.value.some(clienteId => servicio?.cliente === clienteId)
    );

    setServiciosFiltrados(nuevasTiendasFiltradas);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = async () => {
    // Vuelve a obtener los turnos del cuadrante actualizado
    const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);

    // Transforma los turnos en eventos compatibles con FullCalendar
    const eventos = turnos.map((turno) => ({
      id: turno.id,
      vigilantes: turno.vigilante,
      start: turno.inicioTurnoTimestamp,
      end: turno.finTurnoTimestamp,
      turno: turno.turno || 'Sin turno', 
    }));

    // Actualiza el estado de los eventos con los eventos actualizados
    setEvents(eventos);
    setModalOpen(false);
    handleUpdateTurnoComplete()
  };


  const handleServiciosChange = async (event) => {
    const { value } = event.target;
    if (value.includes('all')) {
      setSelectedServicios(isAllTiendasSelected ? [] : servicios.map(servicio => servicio.id));
    } else {
      setIsAllTiendasSelected(value.length === servicios.length);
      setSelectedServicios(event.target.value);

      // Añadir prueba para el primer servicio seleccionado
      if (event.target.value.length > 0) {
        try {
          const primerServicioId = event.target.value[0];
          console.log('🔍 Probando obtención de detalles para servicio:', primerServicioId);
          const detalles = await obtenerDetallesServicio(primerServicioId, currentService);
          console.log('✅ Detalles obtenidos:', detalles);
        } catch (error) {
          console.error('❌ Error en prueba:', error);
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    console.log("servicio actual", currentService.id)
    event.preventDefault();

    try {
      const cuadranteId = await createCuadrante(selectedClientes, currentService, selectedServicios, currentUser.uid);
      setCuadranteId(cuadranteId);
      setMostrarFormularioTurno(true);

      const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);

      const eventos = await Promise.all(
        turnos.map(async (turno) => {
          if (turno.vigilante) {
            const vigilanteData = await obtenerVigilantePorId(turno.vigilante, currentService);

            // Crear fechas correctamente
            const [year, month, day] = turno.fecha.split('-');
            const [horaInicio] = turno.inicioTurno.split(':');
            const [horaFin] = turno.finTurno.split(':');

            const start = new Date(year, month - 1, day, parseInt(horaInicio));
            const end = new Date(year, month - 1, day, parseInt(horaFin));

            // Si la hora de fin es menor que la de inicio, añadir un día
            if (end < start) {
              end.setDate(end.getDate() + 1);
            }

            return {
              id: turno.id,
              title: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : 'Turno sin vigilante',
              start: start,
              end: end,
            };
          }
          return null;
        })
      );

      const eventosValidos = eventos.filter(evento => evento !== null);
      console.log("Eventos creados en submit:", eventosValidos); // Log para debug
      setEvents(eventosValidos);
    } catch (error) {
      console.error('Error al manejar el envío del formulario:', error);
    }
  };

  const handleEventClick = (info) => {
    const turnoId = info.event.id;
    console.log('Evento clicado:', info.event.id);
    setEventoTurnoSeleccionado(turnoId); // Establece el ID del turno seleccionado
    handleOpenModal(); // Abre el modal al hacer clic en el evento
  };

  const handleUpdateTurnoComplete = () => {
    // Limpiar la selección de turno
    setEventoTurnoSeleccionado(null);
  };


  const toggleView = () => {
    setIsGridView(!isGridView); // Alternar entre la vista de calendario y la vista de cuadrícula
  };

  return (
    <div className="cuadrantes">
      <div className="cuadrantesContainer">
        <div className="cuadrantesContent">
          <h1>Crear Cuadrantes</h1>
          <form onSubmit={handleSubmit}>
            <FormControl className="formInput">
              <InputLabel id="clientes-label">Clientes</InputLabel>
              <Select
                labelId="clientes-label"
                id="clientes"
                multiple
                value={selectedClientes}
                onChange={handleClienteChange}
                label="Clientes"
              >
                {clientes.map((cliente) => (
                  <MenuItem key={cliente.id} value={cliente.id}>
                    {cliente.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="formInput">
              <InputLabel id="servicios-label">Servicios</InputLabel>
              <Select
                labelId="servicios-label"
                id="servicios"
                multiple
                value={selectedServicios}
                onChange={handleServiciosChange}
                label="Servicios"
              >
                <MenuItem value="all">
                  <Checkbox checked={isAllTiendasSelected} />
                  <ListItemText primary="Seleccionar todos los servicios" />
                </MenuItem>
                {serviciosFiltrados.map((servicio) => (
                  <MenuItem key={servicio.id} value={servicio.id}>
                    {servicio.numTienda}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">Cuadrante</Button>
            <Button onClick={toggleView} variant="contained" color="primary">
              {isGridView ? "Ver Calendario" : "Ver Vista de Cuadrícula"}
            </Button>
            <Button type="submit" variant="contained" color="primary">Actualizar</Button>
            {cuadranteId && (
              <div>
                <h2>Cuadrante Cargado</h2>
                {/* <p>Cuadrante ID: {cuadranteId}</p> */}
                {/* Agrega más detalles del cuadrante si es necesario */}
              </div>
            )}
            <div className="import-export-buttons">
              <input
                type="file"
                accept=".csv,.txt"
                onChange={handleFileUploadWrapper}
                style={{ display: 'none' }}
                id="import-file"
              />
              <label htmlFor="import-file">
                <Button
                  component="span"
                  variant="contained"
                  color="primary"
                  disabled={isImporting || !cuadranteId}
                >
                  {isImporting ? 'Importando...' : 'Importar Cuadrante'}
                </Button>
              </label>

              <Button
                variant="contained"
                color="primary"
                onClick={handleExportarCuadrante}
                disabled={!cuadranteId}
                style={{ marginLeft: '10px' }}
              >
                Exportar Cuadrante
              </Button>
            </div>
          </form>
          {isGridView ? (
            <div className="gridView">
              <GridView events={events} cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} onMesChange={handleMesChange} />
            </div>
          ) : (
            <div className="calendar">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                displayEventEnd={true}
                initialView="dayGridMonth"
                // editable={true}
                eventClick={handleEventClick}
                events={events}
              />
              <Button onClick={handleOpenModal} variant="contained" color="primary">Crear turno</Button>
              {modalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <Button className="modal-close" onClick={handleCloseModal}>Cerrar </Button>
                    {mostrarFormularioTurno && <CreateTurno cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} />}
                  </div>
                </div>
              )}
              {modalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <Button className="modal-close" onClick={handleCloseModal}>Cerrar</Button>
                    {mostrarFormularioTurno && !eventoTurnoSeleccionado && (
                      <CreateTurno cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} />
                    )}
                    {mostrarFormularioTurno && eventoTurnoSeleccionado && (
                      <ActualizarTurno cuadranteId={cuadranteId} turnoId={eventoTurnoSeleccionado}
                        currentService={currentService} onUpdateComplete={handleUpdateTurnoComplete}
                        servicioId={selectedServicios} onClose={handleCloseModal} />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ExportModal
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        onExport={handleExportWithDesc}
      />
    </div>
  );
};

export default DataCuadrantes;