import React, { useState, useEffect } from 'react';
import { agregarTurno } from './FirestoreCuadrantes';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import './CreateTurno.scss';
import { obtenerVigilantesPorServicio } from './FirestoreCuadrantes';


const CreateTurno = ({ cuadranteId, currentService, servicioId }) => {
  const [inicioTurno, setInicioTurno] = useState('');
  const [finTurno, setFinTurno] = useState('');
  const [arcos, setArcos] = useState(false);
  const [manejoExplosivos, setManejoExplosivos] = useState(false);
  const [peligrosidad, setPeligrosidad] = useState(false);
  const [conArma, setConArma] = useState(false);
  const [observaciones, setObservaciones] = useState('');
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const [vigilantes, setVigilantes] = useState([]); // Estado para almacenar los vigilantes
  const [vigilanteSeleccionado, setVigilanteSeleccionado] = useState({ id: '', persona: '' });

  useEffect(() => {
    console.log('Componente CreateTurno renderizado');
  });

  useEffect(() => {
    const fetchVigilantes = async () => {
      try {
        if (currentService) {
          const vigilantesData = await obtenerVigilantesPorServicio(servicioId, currentService);
          console.log("vigilante data ", vigilantesData)

          setVigilantes(vigilantesData);
        }
      } catch (error) {
        console.error('Error al obtener vigilantes:', error);
      }
    };

    fetchVigilantes();
  }, [currentService]);

  const handleInicioTurnoChange = (event) => {
    setInicioTurno(event.target.value);
  };

  const handleFinTurnoChange = (event) => {
    setFinTurno(event.target.value);
  };

  const handleArcosChange = () => {
    setArcos(!arcos);
  };

  const handleManejoExplosivosChange = () => {
    setManejoExplosivos(!manejoExplosivos);
  };

  const handlePeligrosidadChange = () => {
    setPeligrosidad(!peligrosidad);
  };

  const handleConArmaChange = () => {
    setConArma(!conArma);
  };

  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
  };

  const handleFechaSeleccionadaChange = (event) => {
    setFechaSeleccionada(event.target.value);
  };

  const handleVigilanteChange = (event) => {
    setVigilanteSeleccionado(event.target.value);
  };

  const handleSubmitTurno = async (event) => {
    event.preventDefault();

    try {
      await agregarTurno(cuadranteId, {
        inicioTurno,
        finTurno,
        arcos,
        manejoExplosivos,
        peligrosidad,
        conArma,
        observaciones,
        fecha: fechaSeleccionada,
        vigilante: vigilanteSeleccionado?.persona || '',
        vigilanteId: vigilanteSeleccionado?.id || '',
      }, currentService);

      // Limpiar los campos del formulario después de enviar el turno
      setInicioTurno('');
      setFinTurno('');
      setArcos(false);
      setManejoExplosivos(false);
      setPeligrosidad(false);
      setConArma(false);
      setObservaciones('');
      setFechaSeleccionada('');
      setVigilanteSeleccionado('');
    } catch (error) {
      console.error('Error al agregar turno:', error);
    }
  };

  console.log(vigilantes)
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Turno</h2>
        <form onSubmit={handleSubmitTurno}>
          <label>
            Fecha:
            <input type="date" value={fechaSeleccionada} onChange={handleFechaSeleccionadaChange} />
          </label>
          <label>
            Hora de inicio:
            <input type="time" value={inicioTurno} onChange={handleInicioTurnoChange} />
          </label>
          <label>
            Hora de fin:
            <input type="time" value={finTurno} onChange={handleFinTurnoChange} />
          </label>
          <label>
            Arcos:
            <input type="checkbox" checked={arcos} onChange={handleArcosChange} />
          </label>
          <label>
            Manejo de Explosivos:
            <input type="checkbox" checked={manejoExplosivos} onChange={handleManejoExplosivosChange} />
          </label>
          <label>
            Peligrosidad:
            <input type="checkbox" checked={peligrosidad} onChange={handlePeligrosidadChange} />
          </label>
          <label>
            Con Arma:
            <input type="checkbox" checked={conArma} onChange={handleConArmaChange} />
          </label>
          <select
            id="vigilante"
            value={vigilanteSeleccionado.id}
            onChange={handleVigilanteChange}
          >
            <option value="">Seleccionar vigilante</option>
            {vigilantes.map((vigilante) => (
              <option key={vigilante.id} value={vigilante.id}>
                {vigilante.persona}
              </option>
            ))}
          </select>
          <label>
            Observaciones:
          </label>
          <textarea value={observaciones} onChange={handleObservacionesChange}></textarea>
          <Button type="submit">Crear Turno</Button>
        </form>
      </div>
    </div>
  );
};

export default CreateTurno;
