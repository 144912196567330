import React from 'react';
import './UserDropdown.scss';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LogoutContext } from "../../context/LogoutContext";

const UserDropdown = ({ onClose }) => {
    const handleLogout = useContext(LogoutContext);

    const handleLogoutClick = () => {
        console.log('Logout button clicked');
        handleLogout(); // Llamada a la función handleLogout del contexto
    };

    return (
        <div className="user-dropdown">
            <ul>
                <Link to="/profile" onClick={onClose}>
                    <li>
                        <PersonIcon className="menu-icon" />
                        Mi perfil
                    </li>
                </Link>
                <Link to="/config" onClick={onClose}>
                    <li>
                        <SettingsIcon className="menu-icon" />
                        Gestión de usuarios
                    </li>
                </Link>
                <Link to="/company" onClick={onClose}>
                    <li>
                        <BusinessIcon className="menu-icon" />
                        Gestionar empresas
                    </li>
                </Link>
                <Link to="/login" onClick={handleLogoutClick}>
                    <li>
                        <ExitToAppIcon className="menu-icon" />
                        Logout
                    </li>
                </Link>
            </ul>
        </div>
    );
};

export default UserDropdown;
