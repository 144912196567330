import React, { useState, useEffect } from 'react';
import { actualizarTurno, obtenerTurnoPorId, obtenerVigilantesPorServicio } from './FirestoreCuadrantes';
import { Button } from '@mui/material';
import './CreateTurno.scss';

const ActualizarTurno = ({ cuadranteId, turnoId, currentService, onUpdateComplete, servicioId, onClose }) => {
  const [inicioTurno, setInicioTurno] = useState('');
  const [finTurno, setFinTurno] = useState('');
  const [arcos, setArcos] = useState(false);
  const [manejoExplosivos, setManejoExplosivos] = useState(false);
  const [peligrosidad, setPeligrosidad] = useState(false);
  const [conArma, setConArma] = useState(false);
  const [observaciones, setObservaciones] = useState('');
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const [vigilantes, setVigilantes] = useState([]);
  const [vigilanteSeleccionado, setVigilanteSeleccionado] = useState('');
  const [turnoAsignado, setTurnoAsignado] = useState(''); // Estado para el turno asignado

  useEffect(() => {
    // Aquí debes cargar la información del turno existente utilizando el turnoId
    // Por simplicidad, asumiremos que ya tienes una función para obtener la información del turno
    cargarInformacionTurnoExistente();
    cargarVigilantes();
  }, [turnoId]);

  const cargarInformacionTurnoExistente = async () => {
    try {
      // Aquí deberías implementar la lógica para cargar la información del turno existente
      // Puedes usar la función obtenerTurnoPorId de tu archivo FirestoreCuadrantes.js
      // Por simplicidad, asumiremos que ya tienes implementada esa función y que devuelve un objeto con los datos del turno
      const turnoData = await obtenerTurnoPorId(cuadranteId, turnoId, currentService);

      // Actualizamos el estado con la información del turno existente
      setInicioTurno(turnoData.inicioTurno);
      setFinTurno(turnoData.finTurno);
      setArcos(turnoData.arcos);
      setManejoExplosivos(turnoData.manejoExplosivos);
      setPeligrosidad(turnoData.peligrosidad);
      setConArma(turnoData.conArma);
      setObservaciones(turnoData.observaciones);
      setFechaSeleccionada(turnoData.fecha);
      setTurnoAsignado(turnoData.turno || '');
    } catch (error) {
      console.error('Error al cargar información del turno existente:', error);
    }
  };

  const cargarVigilantes = async () => {
    try {
      const vigilantesData = await obtenerVigilantesPorServicio(servicioId, currentService);
      setVigilantes(vigilantesData);
    } catch (error) {
      console.error('Error al cargar vigilantes:', error);
    }
  };

  const handleInicioTurnoChange = (event) => {
    setInicioTurno(event.target.value);
  };

  const handleFinTurnoChange = (event) => {
    setFinTurno(event.target.value);
  };

  const handleArcosChange = () => {
    setArcos(!arcos);
  };

  const handleManejoExplosivosChange = () => {
    setManejoExplosivos(!manejoExplosivos);
  };

  const handlePeligrosidadChange = () => {
    setPeligrosidad(!peligrosidad);
  };

  const handleConArmaChange = () => {
    setConArma(!conArma);
  };

  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
  };

  const handleFechaSeleccionadaChange = (event) => {
    setFechaSeleccionada(event.target.value);
  };

  const handleVigilanteChange = (event) => {
    setVigilanteSeleccionado(event.target.value);
  };

  const handleTurnoChange = (event) => {
    setTurnoAsignado(event.target.value);
  };


  const handleSubmitTurno = async (event) => {
    event.preventDefault();

    try {
      // Actualizamos el turno con los nuevos datos
      await actualizarTurno(cuadranteId, turnoId, {
        inicioTurno,
        finTurno,
        arcos,
        manejoExplosivos,
        peligrosidad,
        conArma,
        observaciones,
        fecha: fechaSeleccionada,
        vigilante: vigilanteSeleccionado?.persona || '',
        vigilanteId: vigilanteSeleccionado,
        turno: turnoAsignado,
      }, currentService);

      // Limpiar los campos del formulario después de enviar el turno
      setInicioTurno('');
      setFinTurno('');
      setArcos(false);
      setManejoExplosivos(false);
      setPeligrosidad(false);
      setConArma(false);
      setObservaciones('');
      setFechaSeleccionada('');

      // Ejecutar la función onUpdateComplete si está definida
      if (typeof onUpdateComplete === 'function') {
        onUpdateComplete();
      }
    } catch (error) {
      console.error('Error al actualizar turno:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Actualizar Turno</h2>
        <form onSubmit={handleSubmitTurno}>
          <label>
            Fecha:
            <input type="date" value={fechaSeleccionada} onChange={handleFechaSeleccionadaChange} />
          </label>
          <label>
            Hora de inicio:
            <input type="time" value={inicioTurno} onChange={handleInicioTurnoChange} />
          </label>
          <label>
            Hora de fin:
            <input type="time" value={finTurno} onChange={handleFinTurnoChange} />
          </label>
          <label>
            Arcos:
            <input type="checkbox" checked={arcos} onChange={handleArcosChange} />
          </label>
          <label>
            Manejo de Explosivos:
            <input type="checkbox" checked={manejoExplosivos} onChange={handleManejoExplosivosChange} />
          </label>
          <label>
            Peligrosidad:
            <input type="checkbox" checked={peligrosidad} onChange={handlePeligrosidadChange} />
          </label>
          <label>
            Con Arma:
            <input type="checkbox" checked={conArma} onChange={handleConArmaChange} />
          </label>
          <label>
            Vigilante:
            <select value={vigilanteSeleccionado} onChange={handleVigilanteChange}>
              {vigilantes.map((vigilante) => (
                <option key={vigilante.id} value={vigilante.id}>
                  {vigilante.persona}
                </option>
              ))}
            </select>
          </label>
          <label>
            Turno:
            <input
              type="number"
              value={turnoAsignado}
              onChange={(e) => setTurnoAsignado(e.target.value)}
              placeholder="Ingrese el número de turno"
              min="1" // Valor mínimo permitido
              max="10" // Valor máximo permitido, ajusta según tus necesidades
            />
          </label>

          <label>
            Observaciones:
          </label>
          <textarea value={observaciones} onChange={handleObservacionesChange}></textarea>
          <Button type="submit">Actualizar Turno</Button>
          <Button onClick={onClose}>Cancelar</Button> {/* Botón para cerrar la ventana modal sin actualizar el turno */}

        </form>
      </div>
    </div>
  );
};

export default ActualizarTurno;