import React, { useState, useEffect } from 'react';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import { agregarTurno, obtenerVigilantesPorServicio } from './FirestoreCuadrantes';
import './CreateTramo.css';


const CreateTramo = ({ onClose, cuadranteId, servicioId, currentService }) => {
  const [inicioTurno, setInicioTurno] = useState('');
  const [finTurno, setFinTurno] = useState('');
  const [vigilantes, setVigilantes] = useState([]);
  const [vigilanteSeleccionado, setVigilanteSeleccionado] = useState(null);
  const [mesSeleccionado, setMesSeleccionado] = useState('');
  const [arcos, setArcos] = useState(false);
  const [manejoExplosivos, setManejoExplosivos] = useState(false);
  const [peligrosidad, setPeligrosidad] = useState(false);
  const [conArma, setConArma] = useState(false);
  const [observaciones, setObservaciones] = useState('');
  const [diasSeleccionados, setDiasSeleccionados] = useState({});
  const [turnoManual, setTurnoManual] = useState(''); 


  useEffect(() => {
    const fetchVigilantes = async () => {
      try {
        if (currentService) {
          const vigilantesData = await obtenerVigilantesPorServicio(servicioId, currentService);
          setVigilantes(vigilantesData);
        }
      } catch (error) {
        console.error('Error al obtener vigilantes:', error);
      }
    };

    fetchVigilantes();
  }, [currentService, cuadranteId]);

  // Efecto para inicializar los días seleccionados cuando cambia el mes
  useEffect(() => {
    if (mesSeleccionado) {
      const [year, month] = mesSeleccionado.split('-');
      const lastDay = new Date(year, month, 0).getDate();
      const newDiasSeleccionados = {};

      // Inicialmente seleccionar todos los días
      for (let i = 1; i <= lastDay; i++) {
        newDiasSeleccionados[i] = false;
      }

      setDiasSeleccionados(newDiasSeleccionados);
    }
  }, [mesSeleccionado]);

  const getDaysInMonth = () => {
    if (!mesSeleccionado) return [];
    const [year, month] = mesSeleccionado.split('-');
    const date = new Date(year, month - 1, 1);
    const days = [];
    const lastDay = new Date(year, month, 0).getDate();

    // Obtener el día de la semana del primer día (0 = Domingo, 1 = Lunes, etc.)
    const firstDayOfWeek = date.getDay();

    // Añadir días vacíos al principio para alinear el calendario
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(null);
    }

    // Añadir los días del mes
    for (let i = 1; i <= lastDay; i++) {
      days.push(i);
    }

    return days;
  };

  const toggleDia = (dia) => {
    if (dia === null) return; // Ignorar espacios vacíos
    setDiasSeleccionados(prev => ({
      ...prev,
      [dia]: !prev[dia]
    }));
  };

  const handleInicioTurnoChange = (event) => {
    setInicioTurno(event.target.value);
  };

  const handleFinTurnoChange = (event) => {
    setFinTurno(event.target.value);
  };

  const handleVigilanteChange = (event) => {
    console.log(vigilanteSeleccionado)
    const selectedId = event.target.value;
    const selectedVigilante = vigilantes.find(v => v.id === selectedId);
    setVigilanteSeleccionado(selectedVigilante);
  };

  // Función para manejar el cambio en el campo de turno manual
  const handleTurnoManualChange = (event) => {
    setTurnoManual(event.target.value);
  };

  const handleMesChange = (event) => {
    setMesSeleccionado(event.target.value);
  };

  const handleArcosChange = (event) => {
    setArcos(event.target.checked);
  };

  const handleManejoExplosivosChange = (event) => {
    setManejoExplosivos(event.target.checked);
  };

  const handlePeligrosidadChange = (event) => {
    setPeligrosidad(event.target.checked);
  };

  const handleConArmaChange = (event) => {
    setConArma(event.target.checked);
  };

  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
  };

  const handleSubmitTramo = async (event) => {
    event.preventDefault();

    try {
      const [year, month] = mesSeleccionado.split('-');
      const fechasSeleccionadas = [];

      // Solo incluir los días que están seleccionados
      Object.entries(diasSeleccionados).forEach(([dia, seleccionado]) => {
        if (seleccionado) {
          const date = new Date(year, month - 1, parseInt(dia));
          const formattedDate = date.toISOString().split('T')[0];
          fechasSeleccionadas.push(formattedDate);
        }
      });
      console.log('Datos a enviar:', {
        vigilanteId: vigilanteSeleccionado.id,
        vigilante: vigilanteSeleccionado.persona
      });
      await Promise.all(
        fechasSeleccionadas.map((fecha) =>
          agregarTurno(cuadranteId, {
            inicioTurno,
            finTurno,
            fecha,
            vigilante: vigilanteSeleccionado?.persona || '',
            vigilanteId: vigilanteSeleccionado?.id || '',
            turno: turnoManual,
            arcos,
            manejoExplosivos,
            peligrosidad,
            conArma,
            observaciones
          }, currentService)
        )
      );

      onClose(); // Cierra el formulario después de agregar los tramos
    } catch (error) {
      console.error('Error al agregar tramos:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Crear Tramo</h2>
        <form onSubmit={handleSubmitTramo}>
          <label>
            Mes y Año:
            <input
              type="month"
              value={mesSeleccionado}
              onChange={handleMesChange}
            />
            {mesSeleccionado && (
              <div className="calendar-container">
                <h3>Seleccionar días:</h3>
                <div className="calendar-header">
                  <div>Dom</div>
                  <div>Lun</div>
                  <div>Mar</div>
                  <div>Mié</div>
                  <div>Jue</div>
                  <div>Vie</div>
                  <div>Sáb</div>
                </div>
                <div className="calendar-grid">
                  {getDaysInMonth().map((dia, index) => (
                    <div
                      key={index}
                      className={`calendar-day ${dia === null ? 'empty' : ''} ${dia !== null && diasSeleccionados[dia] ? 'selected' : ''
                        }`}
                      onClick={() => toggleDia(dia)}
                    >
                      {dia}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </label>
          <label>
            Hora de inicio:
            <input
              type="time"
              value={inicioTurno}
              onChange={handleInicioTurnoChange}
            />
          </label>
          <label>
            Hora de fin:
            <input
              type="time"
              value={finTurno}
              onChange={handleFinTurnoChange}
            />
          </label>
          <select
            id="vigilante"
            value={vigilanteSeleccionado?.id || ''}
            onChange={handleVigilanteChange}
          >
            <option value="">Seleccionar vigilante</option>
            {vigilantes.map((vigilante) => (
              <option key={vigilante.id} value={vigilante.id}>
                {vigilante.persona}
              </option>
            ))}
          </select>
          <label>
            Turno:
            <input
              type="text"
              id="turnoManual"
              value={turnoManual}
              onChange={handleTurnoManualChange}
              placeholder="Ej: Turno 1"
            />
          </label>
          <FormControlLabel
            control={<Checkbox checked={arcos} onChange={handleArcosChange} />}
            label="Arcos"
          />
          <FormControlLabel
            control={<Checkbox checked={manejoExplosivos} onChange={handleManejoExplosivosChange} />}
            label="Manejo de explosivos"
          />
          <FormControlLabel
            control={<Checkbox checked={peligrosidad} onChange={handlePeligrosidadChange} />}
            label="Peligrosidad"
          />
          <FormControlLabel
            control={<Checkbox checked={conArma} onChange={handleConArmaChange} />}
            label="Con arma"
          />
          <label>
            Observaciones:
            <input
              type="text"
              value={observaciones}
              onChange={handleObservacionesChange}
            />
          </label>
          <Button type="submit">Crear Tramo</Button>
          <Button onClick={onClose}>Cancelar</Button>
        </form>
      </div>
    </div>
  );
};

export default CreateTramo;
