// Mapeo de provincias a códigos de delegación
export const DELEGACION_MAPPING = {
    'Badajoz': '06',
    'Cáceres': '10',
    'Madrid': '28',
    'Barcelona': '08',
    'Valencia': '46',
    'Sevilla': '41',
    'Málaga': '29',
    'Murcia': '30',
    'Alicante': '03',
    'Cádiz': '11'
  };
  
  // Mapeo de empresas a códigos
  export const EMPRESA_MAPPING = {
    'SECOEX': '0001'
  };
  
  // Función helper para obtener código de delegación
  export const getDelegacionCode = (provincia) => {
    return DELEGACION_MAPPING[provincia] || '';
  };
  
  // Función helper para obtener código de empresa
  export const getEmpresaCode = (empresaNombre) => {
    return EMPRESA_MAPPING[empresaNombre] || '';
  };
  
  // Función para extraer contrato y servicio de localización
  export const extractLocalizacionParts = (localizacion) => {
    if (!localizacion) return { contrato: '', servicio: '' };
    const [contrato = '', servicio = ''] = localizacion.split('/');
    return { contrato, servicio };
  };
  
  // Función para validar y formatear los códigos
  export const formatServiceCode = (code) => {
    if (!code) return '';
    return code.padStart(5, '0');
  };