import React, { useState, useEffect, useContext } from 'react';
import "./table.scss";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from '../../firebase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { AuthContext } from "../../context/AuthContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const JornadasTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);
  const { currentService } = useContext(AuthContext);
  
  useEffect(() => {
    const fetchJornadas = async () => {
      try {
        if (!currentService?.id) {
          console.error("ID de servicio no disponible");
          return;
        }

        const registrosRef = collection(db, `servicios/${currentService.id}/registrosJornada`);
        const querySnapshot = await getDocs(registrosRef);
        
        // Agrupar registros por vigilante y fecha
        const jornadasPorVigilante = {};
        
        querySnapshot.docs.forEach(doc => {
          const data = doc.data();
          const fecha = data.timestamp ? data.timestamp.toDate() : new Date();
          const fechaKey = format(fecha, 'yyyy-MM-dd');
          const vigilanteKey = data.vigilanteId;
          
          if (!jornadasPorVigilante[`${vigilanteKey}-${fechaKey}`]) {
            jornadasPorVigilante[`${vigilanteKey}-${fechaKey}`] = {
              inicio: null,
              fin: null,
              vigilanteNombre: data.vigilanteNombre,
              vigilanteApellidos: data.vigilanteApellidos,
              tip: data.vigilanteTipVS,
              tiendas: data.vigilanteTiendas,
              email: data.vigilanteEmail,
              telefono: data.vigilanteTelefono,
              borrado: data.vigilanteBorrado
            };
          }

          if (data.tipo === 'inicio') {
            jornadasPorVigilante[`${vigilanteKey}-${fechaKey}`].inicio = fecha;
          } else if (data.tipo === 'fin') {
            jornadasPorVigilante[`${vigilanteKey}-${fechaKey}`].fin = fecha;
          }
        });

        // Convertir a formato de filas
        const jornadasData = Object.entries(jornadasPorVigilante).map(([key, jornada], index) => ({
          id: key,
          fechaInicio: jornada.inicio,
          fechaFin: jornada.fin,
          vigilanteNombre: jornada.vigilanteNombre || 'Sin nombre',
          vigilanteApellidos: jornada.vigilanteApellidos || '',
          tiendas: Array.isArray(jornada.tiendas) 
            ? jornada.tiendas.map(t => t.numero).join(', ') 
            : 'N/A',
          email: jornada.email || 'N/A',
          tip: jornada.tip || 'N/A',
          telefono: jornada.telefono || 'N/A',
          borrado: jornada.borrado || false,
          duracion: jornada.inicio && jornada.fin ? 
            ((jornada.fin - jornada.inicio) / (1000 * 60 * 60)).toFixed(2) : 
            'Jornada no finalizada'
        }));

        setRows(jornadasData);
      } catch (error) {
        console.error('Error al cargar las jornadas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJornadas();
  }, [currentService]);

  const columns = [
    {
      field: 'fechaInicio',
      headerName: 'Inicio Jornada',
      width: 180,
      valueFormatter: (params) => 
        params.value ? format(params.value, "dd/MM/yyyy HH:mm", { locale: es }) : 'No registrado',
    },
    {
      field: 'fechaFin',
      headerName: 'Fin Jornada',
      width: 180,
      valueFormatter: (params) => 
        params.value ? format(params.value, "dd/MM/yyyy HH:mm", { locale: es }) : 'No registrado',
    },
    {
      field: 'duracion',
      headerName: 'Duración (horas)',
      width: 150,
      valueFormatter: (params) => 
        typeof params.value === 'number' ? `${params.value} h` : params.value,
    },
    {
      field: 'vigilanteNombre',
      headerName: 'Nombre',
      width: 200,
      valueGetter: (params) => 
        `${params.row.vigilanteNombre || ''} ${params.row.vigilanteApellidos || ''}`.trim(),
    },
    // {
    //   field: 'tiendas',
    //   headerName: 'Tiendas Asignadas',
    //   width: 200,
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
    },
    {
      field: 'tip',
      headerName: 'TIP',
      width: 220,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 140,
    }
  ];

  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  const filteredRows = rows.filter(row => showDeleted ? row.borrado : !row.borrado);

  const customLocaleText = {
    noRowsLabel: 'No hay registros',
    footerTotalRows: 'Total de registros:',
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar ASC',
    columnMenuSortDesc: 'Ordenar DESC',
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro de Jornadas</h2>
        <div className="rightActions">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleted}
                onChange={handleShowDeletedChange}
                color="primary"
              />
            }
            label="Mostrar borrados"
          />
        </div>
      </div>
      <DataGridPro
        className="datagrid"
        rows={filteredRows}
        columns={columns}
        loading={loading}
        pageSize={100}
        rowsPerPageOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'fechaInicio', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sx={{
          '& .sin-fin': {
            backgroundColor: '#fff3e0',
          }
        }}
        localeText={customLocaleText}
      />
    </div>
  );
};

export default JornadasTable;