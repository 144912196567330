import { db } from '../../firebase';
import { collection, doc, setDoc, query, where, getDocs, updateDoc, addDoc, getDoc, deleteDoc } from 'firebase/firestore'; // Asegúrate de importar los métodos necesarios
import { 
  getDelegacionCode, 
  getEmpresaCode, 
  extractLocalizacionParts, 
  formatServiceCode 
} from './mappings';

export const generarDatosExportacion = async (turnosFiltrados, currentService, serviciosIds) => {
  try {    
    const detallesServicios = await obtenerDetallesServicios(serviciosIds, currentService);
    const servicioDetails = detallesServicios[0];

    const turnosPorVigilanteServicio = {};
    
    turnosFiltrados.forEach(event => {
      const vigilanteId = event.vigilante;
      
      const key = `${vigilanteId}_${event.servicio}`;
      if (!turnosPorVigilanteServicio[key]) {
        const detallesCompletos = {
          empresa: String(servicioDetails.empresa),     
          delegacion: String(servicioDetails.delegacion), 
          contrato: servicioDetails.contrato,
          servicio: String(servicioDetails.servicio),     // Forzar string
          descripcionServicio: servicioDetails.descripcionServicio || '',
          numTienda: servicioDetails.numTienda,
          empPersona: String(servicioDetails.empresa || '0001'),  // Forzar string
          delgPersona: String(servicioDetails.delegacion || '06'),// Forzar string
          vigilanteId,
          vigilanteNombre: event.title
        };

        turnosPorVigilanteServicio[key] = {
          turnos: {
            HINI: Array(31).fill(''),
            HFIN: Array(31).fill('')
          },
          servicioDetails: detallesCompletos
        };
      }
      
      const [, , day] = event.fecha.split('-');
      const diaIndex = parseInt(day) - 1;
      turnosPorVigilanteServicio[key].turnos.HINI[diaIndex] = event.inicioTurno;
      turnosPorVigilanteServicio[key].turnos.HFIN[diaIndex] = event.finTurno;
    });

    return Object.entries(turnosPorVigilanteServicio).map(([key, data]) => ({
      servicioDetails: data.servicioDetails,
      turnos: data.turnos
    }));

  } catch (error) {
    console.error('Error al generar datos para exportación:', error);
    throw error;
  }
};

export const createCuadrante = async (clienteId, currentService, servicioId, userId) => {
  try {
    console.log("cliente ", clienteId, " tienda ", servicioId)
    // Verifica si ya existe un cuadrante para el mismo cliente y servicio
    const existingCuadranteQuery = await getDocs(query(
      collection(db, 'servicios', currentService.id, 'cuadrantes'),
      where('servicioId', '==', servicioId)
    ));

    console.log("check", servicioId)
    // Si ya existe un cuadrante para el mismo cliente y servicio, actualizar el cuadrante existente
    if (!existingCuadranteQuery.empty) {
      const cuadranteDoc = existingCuadranteQuery.docs[0];
      console.log("check2", servicioId)

      return cuadranteDoc.id; // Devolver el ID del cuadrante actualizado
    }

    // Si no existe un cuadrante para el mismo cliente y servicio, crea uno nuevo
    const cuadranteRef = await setDoc(doc(collection(db, 'servicios', currentService.id, 'cuadrantes')), {
      clienteId: clienteId,
      userId: userId,
      servicioId: servicioId,
      createdAt: new Date(),
    });

    // Devuelve el ID del nuevo cuadrante
    return cuadranteRef.id;
  } catch (error) {
    console.error('Error al crear el cuadrante:', error);
    throw error; // Propaga el error para que pueda ser manejado por el componente
  }
};

export const agregarTurno = async (cuadranteId, turnoData, currentService) => {
  try {
    console.log("check")
    // Obtener la referencia al documento del cuadrante
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);

    // Agregar el turno al subdocumento "turnos" dentro del cuadrante
    const turnoRef = await addDoc(collection(cuadranteRef, 'turnos'), turnoData);
    console.log('Turno agregado con ID: ', turnoRef.id);
    return turnoRef.id; // Devuelve el ID del turno agregado
  } catch (error) {
    console.error('Error al agregar turno: ', error);
    throw error; // Propaga el error para que pueda ser manejado por el componente
  }
};


export const obtenerTurnosPorCuadrante = async (cuadranteId, currentService) => {
  try {
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);
    const turnosSnapshot = await getDocs(collection(cuadranteRef, 'turnos'));
    const turnos = [];

    for (const doc of turnosSnapshot.docs) {
      const turnoData = doc.data();
      
      try {
        // Validar que todos los campos necesarios existan
        if (!turnoData.fecha || !turnoData.inicioTurno || !turnoData.finTurno) {
          console.warn('Turno con datos incompletos:', turnoData);
          continue;
        }

        // Extraer y validar componentes de la fecha
        const [año, mes, dia] = turnoData.fecha.split('-').map(Number);
        const [horaInicio, minutoInicio = '0'] = turnoData.inicioTurno.split(':').map(Number);
        const [horaFin, minutoFin = '0'] = turnoData.finTurno.split(':').map(Number);

        // Validar que todos los componentes sean números válidos
        if (isNaN(año) || isNaN(mes) || isNaN(dia) || 
            isNaN(horaInicio) || isNaN(minutoInicio) ||
            isNaN(horaFin) || isNaN(minutoFin)) {
          console.warn('Componentes de fecha/hora inválidos:', turnoData);
          continue;
        }

        // Crear fechas de inicio y fin
        let fechaInicio = new Date(año, mes - 1, dia, horaInicio, minutoInicio);
        let fechaFin = new Date(año, mes - 1, dia, horaFin, minutoFin);

        // Si la hora de fin es menor que la de inicio, añadir un día a la fecha de fin
        if (horaFin < horaInicio || (horaFin === horaInicio && minutoFin < minutoInicio)) {
          fechaFin.setDate(fechaFin.getDate() + 1);
        }

        // Obtener datos del vigilante si es necesario
        let vigilanteData = null;
        if (turnoData.vigilanteId) {
          try {
            vigilanteData = await obtenerVigilantePorId(turnoData.vigilante, currentService);
          } catch (error) {
            console.warn('No se pudo obtener datos del vigilante:', error);
          }
        }

        // Crear objeto de turno con todos los campos necesarios
        const turnoProcessed = {
          id: doc.id,
          title: vigilanteData ? `${vigilanteData.nombre} ${vigilanteData.apellidos}` : turnoData.vigilante,
          start: fechaInicio,
          end: fechaFin,
          fecha: turnoData.fecha,
          inicioTurno: turnoData.inicioTurno,
          finTurno: turnoData.finTurno,
          turno: turnoData.turno || '',
          vigilante: turnoData.vigilante,
          vigilanteId: turnoData.vigilanteId,
          arcos: turnoData.arcos || false,
          conArma: turnoData.conArma || false,
          manejoExplosivos: turnoData.manejoExplosivos || false,
          peligrosidad: turnoData.peligrosidad || false,
          observaciones: turnoData.observaciones || ''
        };

        turnos.push(turnoProcessed);

        console.log('Turno procesado exitosamente:', turnoProcessed);

      } catch (error) {
        console.error('Error procesando turno:', doc.id, turnoData, error);
      }
    }

    return turnos;
  } catch (error) {
    console.error('Error al obtener turnos por cuadrante:', error);
    throw error;
  }
};

export const actualizarTurno = async (cuadranteId, turnoId, turnoData, currentService) => {
  try {
    // Obtener la referencia al documento del cuadrante y el turno específico
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);
    const turnoRef = doc(cuadranteRef, 'turnos', turnoId);

    // Actualizar los datos del turno específico
    await updateDoc(turnoRef, turnoData);

    console.log('Turno actualizado con ID:', turnoId);
  } catch (error) {
    console.error('Error al actualizar turno:', error);
    throw error;
  }
};

export const obtenerTurnoPorId = async (cuadranteId, turnoId, currentService) => {
  try {
    // Obtener la referencia al documento del cuadrante y el turno específico
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);
    const turnoRef = doc(cuadranteRef, 'turnos', turnoId);

    // Obtener los datos del turno específico
    const turnoDoc = await getDoc(turnoRef);

    if (turnoDoc.exists()) {
      const turnoData = turnoDoc.data();
      // Convertir la fecha y hora de inicio del turno a un timestamp
      const inicioTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.inicioTurno}:00`).getTime();
      // Convertir la fecha y hora de fin del turno a un timestamp
      const finTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.finTurno}:00`).getTime();

      const turno = {
        id: turnoDoc.id,
        ...turnoData,
        inicioTurnoTimestamp: inicioTurnoTimestamp,
        finTurnoTimestamp: finTurnoTimestamp
      };

      return turno;
    } else {
      throw new Error('El turno especificado no existe.');
    }
  } catch (error) {
    console.error('Error al obtener turno por ID:', error);
    throw error;
  }
};

export const obtenerDetallesServicio = async (servicioId, currentService) => {
  try {
    const servicioDoc = await getDoc(doc(db, 'servicios', currentService.id, 'tiendas', servicioId));
    
    if (!servicioDoc.exists()) {
      throw new Error('Servicio no encontrado');
    }

    const servicioData = servicioDoc.data();

    // Usar los mappings directamente
    const empresa = getEmpresaCode(servicioData.empresaNombre);
    const delegacion = getDelegacionCode(servicioData.provincia);
    const { contrato, servicio } = extractLocalizacionParts(servicioData.localizacion);

    const resultData = {
      empresa,
      delegacion,
      contrato,
      servicio,
      descripcionServicio: servicioData.numTienda || '',
      numTienda: servicioData.numTienda,
      empPersona: empresa,
      delgPersona: delegacion
    };

    console.log('Datos del servicio:', resultData);
    return resultData;

  } catch (error) {
    console.error('Error al obtener detalles del servicio:', error);
    throw error;
  }
};

// Function to fetch multiple services
export const obtenerDetallesServicios = async (serviciosIds, currentService) => {
  try {
    const detallesServicios = await Promise.all(
      serviciosIds.map(servicioId => obtenerDetallesServicio(servicioId, currentService))
    );
    console.log("detalles servicios, ", detallesServicios )
    return detallesServicios;
  } catch (error) {
    console.error('Error al obtener detalles de múltiples servicios:', error);
    throw error;
  }
};

export const obtenerVigilantesPorServicio = async (servicioId, currentService) => {
  try {
    const vigilantes = [];
    
    // Validar que servicioId y currentService sean válidos
    if (!servicioId || !currentService?.id) {
      console.error('servicioId o currentService inválidos:', { servicioId, currentService });
      throw new Error('servicioId y currentService son requeridos');
    }

    // Asegurarse de que servicioId sea un string si es un solo ID
    // o sea un array si son múltiples IDs
    const servicioIdToUse = Array.isArray(servicioId) ? servicioId[0] : servicioId;


    // Obtener el servicio de la colección tiendas
    const servicioDoc = await getDoc(doc(db, 'servicios', currentService.id, 'tiendas', servicioIdToUse));

    if (servicioDoc.exists()) {
      const servicioData = servicioDoc.data();
      const numTienda = servicioData.numTienda;


      // Realizar una consulta para obtener los vigilantes asignados al servicio
      const vigilantesSnapshot = await getDocs(query(
        collection(db, 'servicios', currentService.id, 'vigilantes')
      ));

      // Iterar sobre los documentos de los vigilantes
      vigilantesSnapshot.forEach((vigilanteDoc) => {
        const vigilanteData = vigilanteDoc.data();

        // Verificar si el vigilante tiene tiendas asignadas
        if (vigilanteData.tiendas && Array.isArray(vigilanteData.tiendas)) {
          vigilanteData.tiendas.forEach((tienda) => {
            // Verificar que tienda sea un objeto válido y tenga la propiedad numero
            if (tienda && typeof tienda === 'object' && tienda.numero === numTienda) {
              // Evitar duplicados antes de agregar
              const existeVigilante = vigilantes.some(v => v.id === vigilanteDoc.id);
              if (!existeVigilante) {
                vigilantes.push({
                  id: vigilanteDoc.id,
                  persona: vigilanteData.persona || '',
                  ...vigilanteData
                });
              }
            }
          });
        }
      });

      return vigilantes;
    } else {
      console.error('Servicio no encontrado:', servicioIdToUse);
      throw new Error('El servicio especificado no existe en la colección tiendas.');
    }
  } catch (error) {
    console.error('Error al obtener vigilantes por servicio:', error);
    throw error;
  }
};

export const obtenerVigilantePorId = async (vigilanteId, currentService) => {
  try {
    // Realizar una consulta para obtener todos los vigilantes que tengan el campo persona
    const vigilantesRef = collection(db, 'servicios', currentService.id, 'vigilantes');
    const querySnapshot = await getDocs(vigilantesRef);
    
    // Filtrar los vigilantes que tienen el campo persona
    const vigilantesConPersona = querySnapshot.docs.filter(doc => doc.data().persona);
    
    console.log('Vigilantes con campo persona:', vigilantesConPersona.map(doc => ({
      id: doc.id,
      persona: doc.data().persona
    })));

    // Buscar el vigilante donde el campo persona coincida con el ID como string
    const vigilanteDoc = vigilantesConPersona.find(doc => {
      const personaId = vigilanteId.toString();
      const docPersona = doc.data().persona.toString();
      const coincide = docPersona === personaId;
      if (coincide) {
        console.log('¡Coincidencia encontrada!');
      }
      return coincide;
    });

    if (vigilanteDoc) {
      const vigilanteData = {
        id: vigilanteDoc.id,
        ...vigilanteDoc.data()
      };
      return vigilanteData;
    } else {
      console.warn(`No se encontró vigilante con persona ${vigilanteId}. Path: servicios/${currentService.id}/vigilantes`);
      throw new Error(`El vigilante con persona ${vigilanteId} no existe.`);
    }
  } catch (error) {
    console.error('Error al obtener vigilante por persona:', error);
    console.error('Stack:', error.stack);
    throw error;
  }
};

// Función para eliminar una fila (y sus turnos asociados)
export const eliminarFilaCompleta = async (eventIds, currentService, cuadranteId ) => {
  try {
    console.log(eventIds)
    // Obtener la referencia al documento del turno
    const turnoRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId, 'turnos', eventIds);

    // Eliminar el turno específico
    await deleteDoc(turnoRef);

  } catch (error) {
    console.error('Error al eliminar fila completa y sus turnos asociados:', error);
    throw error;
  }
};

const createShortHash = (value) => {
  // Asegurarse de que el valor sea una cadena de texto
  const str = String(value);
  
  // Verificar si el valor es válido después de la conversión
  if (!str) {
    throw new Error('Invalid value provided for creating hash');
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convertir a 32 bits
  }
  return Math.abs(hash).toString(36).substring(0, 8); // Convertir a base 36 y tomar los primeros 8 caracteres
};