import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import React, { useState } from 'react';

const ExportModal = ({ open, onClose, onExport }) => {
  const [descContrato, setDescContrato] = useState('');

  const handleExport = () => {
    onExport(descContrato);
    setDescContrato('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Exportar Cuadrante</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="descContrato"
          label="Descripción del Contrato"
          type="text"
          fullWidth
          variant="outlined"
          value={descContrato}
          onChange={(e) => setDescContrato(e.target.value)}
          className="mt-4"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleExport} color="primary" variant="contained">
          Exportar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;