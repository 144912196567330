import React from 'react';
import "./list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import JornadasTable from "../../components/table/JornadasTable";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";


const Jornadas = () => {
  const { collapsed } = useSidebarState();
  const containerClass = collapsed ? "listContainer collapsed" : "listContainer";

  return (
    <div data-testid="sidebar2" className="list">
      <Sidebar />
      <div data-testid="navbar2" className={containerClass}>
        <Navbar />
        <JornadasTable />
      </div>
    </div>
  )
}

export default Jornadas;