import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import UpdateBox from "../../components/infoBox/UpdateBox";
import { useEffect, useState, useContext } from "react";
import { collection, query, getDocs, where, doc, getDoc, limit, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import BarChartWidget from '../../components/chart/BarChartWidget';
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

const Home = () => {
  const [widgetData, setWidgetData] = useState({
    vigilantes: { amount: null, growthPercentage: 0 },
    tiendas: { amount: null, growthPercentage: 0 },
    documents: { amount: null, growthPercentage: 0 },
  });
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [servicioId, setServicioId] = useState(null);

  const { currentUser, adminData } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";

  const chartData = Object.keys(widgetData).map(key => ({
    name: key,
    amount: widgetData[key].amount,
    growthPercentage: widgetData[key].growthPercentage,
  }));

  useEffect(() => {
    const fetchServicioId = async () => {
      if (currentUser?.email) {
        const serviciosRef = collection(db, "servicios");
        const q = query(serviciosRef, where("admins", "array-contains", currentUser.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const servicioDoc = querySnapshot.docs[0];
          setServicioId(servicioDoc.id);
        }
      }
    };

    fetchServicioId();
  }, [currentUser]);

  useEffect(() => {
    if (servicioId) {
      fetchData("vigilantes");
      fetchData("tiendas");
      fetchData("documents");
      fetchRecentDocuments();
    }
  }, [servicioId]);

  const fetchRecentDocuments = async () => {
    if (!adminData?.clientes || !servicioId) return;

    try {
      // Fetch client names from the "clientes" collection
      const clientNamesPromises = adminData.clientes.map(async clientId => {
        if (!clientId) return null;
        
        const clientDocRef = doc(db, "servicios", servicioId, "clientes", clientId);
        const clientDocSnapshot = await getDoc(clientDocRef);

        if (clientDocSnapshot.exists()) {
          return clientDocSnapshot.data().nombre;
        }
        return null;
      });

      const clientNames = await Promise.all(clientNamesPromises);
      const validClientNames = clientNames.filter(name => name !== null);

      const recentDocs = [];

      for (const clientName of validClientNames) {
        const documentsRef = collection(db, "servicios", servicioId, "documents");
        const q = query(
          documentsRef, 
          where("cliente", "==", clientName), 
          orderBy("fecha", "desc"), 
          limit(5)
        );
        
        const querySnapshot = await getDocs(q);

        for (const docSnapshot of querySnapshot.docs) {
          const documentData = docSnapshot.data();
          if (!documentData?.vigilante) continue;

          const vigilanteId = documentData.vigilante.replace('@gmail.com', '');
          const vigilanteQuery = query(
            collection(db, "servicios", servicioId, "vigilantes"), 
            where("tipVS", "==", vigilanteId)
          );
          
          const vigilanteSnapshot = await getDocs(vigilanteQuery);

          if (!vigilanteSnapshot.empty) {
            const vigilanteData = vigilanteSnapshot.docs[0].data();
            recentDocs.push({ ...documentData, vigilanteData });
          } else {
            recentDocs.push(documentData);
          }
        }
      }

      setRecentDocuments(recentDocs);
    } catch (error) {
      console.error("Error fetching recent documents:", error);
    }
  };

  const fetchData = async (type) => {
    if (!servicioId) return;

    try {
      const monthlyDataRef = doc(db, 'monthlyData', servicioId, type, 'currentMonth');
      const monthlyDataDoc = await getDoc(monthlyDataRef);

      if (monthlyDataDoc.exists()) {
        const monthlyData = monthlyDataDoc.data();
        setWidgetData(prevData => ({
          ...prevData,
          [type]: {
            amount: monthlyData.totalAllTime || 0,
            growthPercentage: monthlyData.totalLastMonth || 0,
          }
        }));
      }
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  };

  function formatDate(timestamp) {
    if (!timestamp?.toDate) return '';
    const date = timestamp.toDate();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  }

  return (
    <div data-testid="sidebar1" className="home">
      <Sidebar />
      <div data-testid="navbar1" className={containerClass}>
        <Navbar />
        <div className="user-greeting">
          <h2>Hola {currentUser?.displayName || currentUser?.email || 'Usuario'} !</h2>
        </div>
        <div data-testid="widget" className="widgets">
          <Widget type="vigilantes" data={widgetData.vigilantes} />
          <Widget type="tiendas" data={widgetData.tiendas} />
          <Widget type="documents" data={widgetData.documents} />
        </div>
        <BarChartWidget data={chartData} />

        <div className="box-container">
          <div className="service-info-box recent-documents">
            <h2>Última actividad</h2>
            <ul>
              {recentDocuments.map((doc, index) => (
                <li key={index} className="document-item">
                  <div className="info">
                    <div className="name-text">
                      <br />
                      <span className="blue-text">{doc.documentTypeId}</span>
                      <p>
                        {doc.vigilanteData?.nombre} {doc.vigilanteData?.apellidos}
                      </p>
                    </div>
                    <p className="date-text">{formatDate(doc.fecha)}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <UpdateBox />
        </div>
      </div>
    </div>
  );
};

export default Home;