import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from "../../context/AuthContext";
import { db } from '../../firebase';
import { Calendar } from 'react-big-calendar';
import './EditVigilante.scss';

const EditVigilante = () => {
    const { vigilanteId } = useParams();
    const { currentService } = useContext(AuthContext);
    const [vigilante, setVigilante] = useState(null);

    useEffect(() => {
        const fetchVigilante = async () => {
            try {
                if (currentService && vigilanteId) {
                    const vigilanteDoc = doc(db, 'servicios', currentService.id, 'vigilantes', vigilanteId);
                    const vigilanteSnapshot = await getDoc(vigilanteDoc);
                    
                    if (vigilanteSnapshot.exists()) {
                        setVigilante({
                            id: vigilanteSnapshot.id,
                            ...vigilanteSnapshot.data()
                        });
                    } else {
                        console.error('Vigilante no encontrado');
                    }
                }
            } catch (error) {
                console.error('Error al obtener datos del vigilante:', error);
            }
        };

        fetchVigilante();
    }, [currentService, vigilanteId]);

    if (!vigilante) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="edit-vigilante-container">
            <div className="header">
                <h1>{vigilante.nombre}</h1>
                <div className="vigilante-details">
                    <p><strong>Persona:</strong> {vigilante.persona}</p>
                    <p><strong>TIP:</strong> {vigilante.tip}</p>
                </div>
            </div>

            <div className="calendar-section">
                <h2>Calendario de Turnos</h2>
                <div className="calendar">
                    <Calendar
                        events={[]}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditVigilante;