import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { db } from '../../firebase';
import { collection, getDocs, query, where } from "firebase/firestore";
import { AuthContext } from "../../context/AuthContext";
import SearchIcon from '@mui/icons-material/Search';

/**
 * Hook que maneja la carga de documentos y muestra una vista de carga mientras se obtienen los datos.
 * @param {string} tipVS - El parámetro tipVS recibido desde el URL.
 * @param {string} vigilante - El ID del vigilante específico.
 * @returns {JSX.Element} - Componente List con la vista de carga o los documentos filtrados.
 */
const List = ({ vigilante }) => {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const { tipVS } = useParams();

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const documentsPerPage = 10;


  /**
   * Función que obtiene los documentos de la base de datos y los almacena en el estado.
   * @returns {Promise<void>} - Resuelve una vez que se han obtenido y almacenado los documentos.
   */
  const fetchDocuments = async () => {
    try {
      const serviciosRef = collection(db, 'servicios');
      const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
      const querySnapshot = await getDocs(q);
      const documents = [];

      querySnapshot.forEach((doc) => {
        const servicioData = doc.data();
        const servicioId = doc.id;

        const documentsRef = collection(serviciosRef, servicioId, 'documents');
        getDocs(documentsRef).then((documentsSnapshot) => {
          documentsSnapshot.forEach((document) => {
            const documentData = { id: document.id, ...document.data() };

            // Filtrar solo los documentos relacionados con el vigilante específico
            if (documentData.vigilante && documentData.vigilante === vigilante) {
              // Acceder a los campos 'seconds' y 'nanoseconds' del objeto 'fecha'
              const timestamp = documentData.fecha;
              const seconds = timestamp.seconds;
              const nanoseconds = timestamp.nanoseconds;

              // Construir la fecha a partir de los campos 'seconds' y 'nanoseconds'
              const date = new Date(seconds * 1000 + nanoseconds / 1000000);
              const dia = date.getDate();
              const mes = date.getMonth() + 1;
              const año = date.getFullYear();
              const fechaFormateada = `${dia}/${mes}/${año}`;

              documentData.fecha = fechaFormateada;

              documents.push(documentData);
            }
          });
          setRows(documents);
          console.log('documentos', documents)
        });
      });
    } catch (error) {
      console.error('Error al obtener los documentos:', error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDocuments().then(() => setLoading(false));
  }, [tipVS, vigilante]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleNextPage = () => {
    setPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setPage(prev => prev - 1);
  };


  /**
 * Maneja el cambio en el valor del campo de búsqueda.
 * @param {Object} event - Evento de cambio de valor.
 */
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  /**
   * Filtra las filas de documentos en base al término de búsqueda.
   * @param {Object} row - Objeto de datos del documento.
   * @returns {boolean} - Devuelve true si el documento coincide con el término de búsqueda, de lo contrario, devuelve false.
   */
  //... (Resto del código)

  const filteredRows = rows.filter((row) => {
    if (row.documentTypeId && row.fecha !== undefined) {
      return (
        row.documentTypeId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.fecha.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  });

  const displayedDocuments = filteredRows.slice((page - 1) * documentsPerPage, page * documentsPerPage);

  const totalPages = Math.ceil(filteredRows.length / documentsPerPage);

  const getPaginationNumbers = () => {
    let numbers = [];
    for (let i = 1; i <= 5 && i <= totalPages; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  return (
    <div className="tableContainerSingle">
      <h2 className="datatableTitle">Reportes</h2>
      <div className="rightActions">
        <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}
        </div>
      </div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Tipo de reporte</TableCell>
              <TableCell className="tableCell">Fecha</TableCell>
              <TableCell className="tableCell">Accciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedDocuments.map((row) => (
              <TableRow key={row.id}>
                <TableCell className="tableCell">{row.documentTypeId}</TableCell>
                <TableCell className="tableCell">{row.fecha}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellAction">
                    <Link to={`/vigilantes/details/${row.id}`}>
                      <div className="viewButtonSingle">Ver</div>
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="pagination-controls">
          <button className="pagination-button" onClick={handlePreviousPage} disabled={page === 1}>&lt;</button>
          {getPaginationNumbers().map(num => (
            <button
              key={num}
              className={`pagination-button ${num === page ? 'active-page' : ''}`}
              onClick={() => setPage(num)}
            >
              {num}
            </button>
          ))}
          <button className="pagination-button" onClick={handleNextPage} disabled={page === totalPages}>&gt;</button>
        </div>
      </TableContainer>
    </div>
  );
};

export default List;
