import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { db, storage } from '../../firebase';
import { collection, addDoc, getDocs, query, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, Paper, Typography, TextField } from '@mui/material';
import "./styles/Companies.scss";

const Companies = () => {
    const { currentUser, currentService } = useContext(AuthContext);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newCompany, setNewCompany] = useState({
        nombre: '',
        cif: '',
        logo: null
    });
    const [previewUrl, setPreviewUrl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentService?.id) {
            return;
        }
        fetchCompanies();
    }, [currentService]);

    const fetchCompanies = async () => {
        if (!currentService?.id) {
            console.error("No hay servicio seleccionado");
            return;
        }

        try {
            const companiesRef = collection(db, 'servicios', currentService.id, 'empresas');
            const companiesSnap = await getDocs(query(companiesRef));
            const companiesData = companiesSnap.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setCompanies(companiesData);
        } catch (error) {
            console.error("Error al cargar empresas:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCompany(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewCompany(prev => ({
                ...prev,
                logo: file
            }));

            // Crear preview del logo
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let logoUrl = '';
            if (newCompany.logo) {
                // Subir logo al Storage
                const logoRef = ref(storage, `servicios/${currentService.id}/empresas/logos/${newCompany.logo.name}`);
                await uploadBytes(logoRef, newCompany.logo);
                logoUrl = await getDownloadURL(logoRef);
            }

            // Añadir empresa a Firestore
            const companyData = {
                nombre: newCompany.nombre,
                cif: newCompany.cif,
                logoUrl: logoUrl,
                createdAt: new Date()
            };

            const companiesRef = collection(db, 'servicios', currentService.id, 'empresas');
            await addDoc(companiesRef, companyData);

            // Limpiar formulario y recargar empresas
            setNewCompany({
                nombre: '',
                cif: '',
                logo: null
            });
            setPreviewUrl(null);
            await fetchCompanies();

            alert('Empresa añadida correctamente');
        } catch (error) {
            console.error("Error al añadir empresa:", error);
            alert('Error al añadir la empresa');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="companies-container">
            <Typography variant="h4" component="h1" className="companies-title">
                Gestión de Empresas
            </Typography>

            {/* Formulario para añadir empresa */}
            <Paper elevation={3} className="company-form-paper">
                <Typography variant="h6">Añadir Nueva Empresa</Typography>
                <form onSubmit={handleSubmit} className="company-form">
                    <TextField
                        name="nombre"
                        label="Nombre de la Empresa"
                        value={newCompany.nombre}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="cif"
                        label="CIF"
                        value={newCompany.cif}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <div className="logo-upload">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            id="logo-input"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="logo-input">
                            <Button variant="contained" component="span">
                                Subir Logo
                            </Button>
                        </label>
                        {previewUrl && (
                            <div className="logo-preview">
                                <img src={previewUrl} alt="Logo preview" />
                            </div>
                        )}
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        className="submit-button"
                    >
                        {isLoading ? 'Añadiendo...' : 'Añadir Empresa'}
                    </Button>
                </form>
            </Paper>

            {/* Lista de empresas */}
            <div className="companies-list">
                {isLoading ? (
                    <p>Cargando empresas...</p>
                ) : (
                    companies.map((company) => (
                        <Paper key={company.id} elevation={3} className="company-paper">
                            <div className="company-info">
                                {company.logoUrl && (
                                    <div className="company-logo">
                                        <img src={company.logoUrl} alt={`Logo de ${company.nombre}`} />
                                    </div>
                                )}
                                <div className="company-details">
                                    <Typography variant="h6">{company.nombre}</Typography>
                                    <Typography variant="body1">CIF: {company.cif}</Typography>
                                </div>
                            </div>
                        </Paper>
                    ))
                )}
            </div>
        </div>
    );
};

export default Companies;