import './style/styles.css'
import ArrayCellRenderer from './ArrayCellRenderer';


export const userColumns = [
  {
    field: "id", headerName: "ID",
    width: 200,
    hide: true // Oculta la columna en la vista,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    width: 200,
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    width: 200,
  },
  {
    field: "persona",
    headerName: "Persona",
    width: 150,
    renderCell: (params) => {
      if (!params.value) return null;
      return (
        <div style={{
          borderRadius: '10px',
          background: 'rgba(73, 80, 87, 0.27)',
          padding: '5px 10px',
          color: 'black',
          whiteSpace: 'nowrap'
        }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "email",
    with: 200,
  },
  {
    field: "tiendas",
    headerName: "Servicios",
    width: 200,
    renderCell: (params) => (
      <div className="custom-scrollbar" style={{
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value?.map ? params.value.map((tienda, index) => (
          <div key={index} style={{
            borderRadius: '10px',
            background: 'rgba(73, 80, 87, 0.27)',
            padding: '5px 10px',
            color: 'black',
            marginRight: '5px',
            marginBottom: '5px'
          }}>
            {tienda}
          </div>
        )) : null}
      </div>
    ),
  },
  {
    field: "clientes",
    headerName: "Clientes",
    width: 200,
    renderCell: (params) => (
      <div className="custom-scrollbar" style={{
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value?.map ? params.value.map((cliente, index) => (
          <div key={index} style={{
            borderRadius: '10px',
            background: 'rgba(73, 80, 87, 0.27)',
            padding: '5px 10px',
            color: 'black',
            marginRight: '5px',
            marginBottom: '5px'
          }}>
            {cliente}
          </div>
        )) : null}
      </div>
    ),
  },
  {
    field: "timeStamp",
    headerName: "Fecha de Registro",
    minWith: 150,
    renderCell: (params) => {
      // Formatear el timeStamp a una cadena legible directamente en renderCell
      const formattedDate = new Date(params.value?.seconds * 1000).toLocaleString();
      return formattedDate;
    },
  },
  {
    field: "tipVS",
    headerName: "Tip VS",
    minWidth: 150,
  },
  {
    field: "dni",
    headerName: "DNI",
    width: 150,
  },
];

export const tiendasColumns = [
  { field: "id", headerName: "ID", width: 200, hide: true },
  {
    field: "nombreCliente",
    headerName: "Cliente",
    width: 200,
    hide: true,
  },
  {
    field: "localizacion",
    headerName: "Localización",
    width: 150,
  },
  {
    field: "numTienda",
    headerName: "Identificador de Servicio",
    width: 200,
    renderCell: (params) => (
      <div style={{
        borderRadius: '10px',
        background: 'rgba(73, 80, 87, 0.27)',
        padding: '5px 10px',
        color: 'black',
        whiteSpace: 'nowrap'  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "provincia",
    headerName: "Provincia",
    type: "text",
    placeholder: "Provincia",
  },
  {
    field: "comunidad",
    headerName: "Comunidad",
    type: "text",
    placeholder: "Comunidad",
  },
  {
    field: "inspectores",
    headerName: "Inspectores",
    width: 250,
    valueGetter: (params) => {
      // Combinar y eliminar duplicados de ambos campos
      const inspectores = [
        ...(params.row.inspectores || []),
        ...(params.row.inspectoresAsignados || [])
      ];
      return [...new Set(inspectores)];
    },
    renderCell: (params) => <ArrayCellRenderer values={params.value} />
  },
  {
    field: "administrativos",
    headerName: "Administrativos",
    width: 250,
    valueGetter: (params) => {
      // Combinar y eliminar duplicados de ambos campos
      const administrativos = [
        ...(params.row.administrativos || []),
        ...(params.row.administrativosAsignados || [])
      ];
      return [...new Set(administrativos)];
    },
    renderCell: (params) => <ArrayCellRenderer values={params.value} />
  },
  {
    field: "empresaNombre",
    headerName: "Empresa",
    width: 200,
    renderCell: (params) => {
      if (!params.value) return null; // Si no hay empresa, no mostrar nada
      return (
        <div style={{
          borderRadius: '10px',
          background: 'rgba(73, 80, 87, 0.27)',
          padding: '5px 10px',
          color: 'black',
          whiteSpace: 'nowrap'
        }}>
          {params.value}
        </div>
      );
    },
  },
];

export const clientesColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    hide: true,
  },
  {
    field: "nombre",
    headerName: "nombre",
    width: 150,
  },
  {
    field: "administrativosAsignados",  // Campo para administrativos
    headerName: "Administrativos",
    width: 250,
    renderCell: (params) => (
      <div style={{
        borderRadius: '10px',
        background: 'rgba(73, 80, 87, 0.27)',
        padding: '5px 10px',
        color: 'black',
        whiteSpace: 'nowrap',  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value}
      </div>
    ),
  },
  {
    field: "inspectoresAsignados",  // Campo para inspectores
    headerName: "Inspectores",
    width: 250,
    renderCell: (params) => (
      <div style={{
        borderRadius: '10px',
        background: 'rgba(73, 80, 87, 0.27)',
        padding: '5px 10px',
        color: 'black',
        whiteSpace: 'nowrap',  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value}
      </div>
    ),
  },
];


