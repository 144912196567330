import "./update.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  doc,
  serverTimestamp,
  setDoc,
  getDocs,
  getDoc,
  where,
  collection,
  query,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { uploadErrorLogToFirestore } from "../../utils/logUtils";
import { fetchAdministrativosAsignados, fetchInspectoresAsignados } from "../new/utils/FirebaseUtils";
import { comunidades, provincias } from "../../zoneData";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

/**
 * Componente para la actualización de una tienda.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.inputs - Datos de entrada para el formulario.
 * @param {string} props.title - Título del componente.
 * @returns {JSX.Element} - Componente UpdateTienda.
 */
const UpdateTienda = ({ inputs, title }) => {
  const { currentUser, currentService, adminData } = useContext(AuthContext);


  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(""); // Para la imagen actual
  const [newFile, setNewFile] = useState(null); // Para la nueva imagen a subir
  const [inspectoresAsignados, setInspectoresAsignados] = useState([]);
  const [administrativosAsignados, setAdministrativosAsignados] = useState([]);
  const [selectedInspectores, setSelectedInspectores] = useState([]);
  const [selectedAdministrativos, setSelectedAdministrativos] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [adminProvincias, setAdminProvincias] = useState(adminData ? adminData.provincias || [] : []);
  const [adminComunidades, setAdminComunidades] = useState(adminData ? adminData.comunidades || [] : []);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState('');


  const navigate = useNavigate();
  const { tiendaId } = useParams();
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    // Comprobar si currentService está disponible
    if (currentService) {
      const currentServiceId = currentService.id;

      // Obtener inspectores asignados
      fetchInspectoresAsignados(currentServiceId).then((inspectores) => {
        setInspectoresAsignados(inspectores);
      });

      // Obtener administrativos asignados
      fetchAdministrativosAsignados(currentServiceId).then((administrativos) => {
        setAdministrativosAsignados(administrativos);
      });

      console.log(inspectoresAsignados, inspectoresAsignados)
    }
  }, [currentService]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!currentService) return;

        const tiendaRef = doc(db, "servicios", currentService.id, "tiendas", tiendaId);
        const tiendaDoc = await getDoc(tiendaRef);

        if (tiendaDoc.exists()) {
          const tiendaData = tiendaDoc.data();

          // Actualizar data con todos los campos
          setData({
            ...tiendaData,
            provincia: tiendaData.provincia || '',
            comunidad: tiendaData.comunidad || ''
          });

          // Actualizar estados individuales
          setCurrentImageUrl(tiendaData.img || '');
          setSelectedInspectores(tiendaData.inspectores || tiendaData.inspectoresAsignados || []);
          setSelectedAdministrativos(tiendaData.administrativos || tiendaData.administrativosAsignados || []);
          setSelectedEmpresa(tiendaData.empresaId || '');

          // Asegurarse de que las provincias y comunidades del admin estén cargadas
          if (adminData) {
            setAdminProvincias(adminData.provincias || []);
            setAdminComunidades(adminData.comunidades || []);
          }

          // Log para debug
          console.log('Datos cargados:', {
            tiendaData,
            provincia: tiendaData.provincia,
            comunidad: tiendaData.comunidad,
            inspectores: tiendaData.inspectores || tiendaData.inspectoresAsignados,
            administrativos: tiendaData.administrativos || tiendaData.administrativosAsignados
          });
        }
      } catch (err) {
        console.error("Error al cargar datos:", err);
      }
    };

    fetchData();
  }, [currentService, tiendaId, adminData]); // Añadimos adminData como dependencia

  useEffect(() => {
    const loadAssignedUsers = async () => {
      if (!currentService?.id) return;

      try {
        const inspectores = await fetchInspectoresAsignados(currentService.id);
        const administrativos = await fetchAdministrativosAsignados(currentService.id);

        setInspectoresAsignados(inspectores);
        setAdministrativosAsignados(administrativos);

        console.log('Usuarios cargados:', { inspectores, administrativos });
      } catch (error) {
        console.error('Error al cargar usuarios:', error);
      }
    };

    loadAssignedUsers();
  }, [currentService]);

  useEffect(() => {
    const fetchEmpresas = async () => {
      if (!currentService) return;

      try {
        const empresasRef = collection(db, 'servicios', currentService.id, 'empresas');
        const empresasSnap = await getDocs(query(empresasRef));
        const empresasData = empresasSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmpresas(empresasData);
      } catch (error) {
        console.error("Error al cargar empresas:", error);
      }
    };

    fetchEmpresas();
  }, [currentService]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          uploadErrorLogToFirestore(error)
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
            setCurrentImageUrl(downloadURL); // Actualiza la URL de la imagen
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  /**
 * Maneja el cambio de los campos de entrada del formulario.
 *
 * @param {Object} e - Evento de cambio.
 */
  const handleInput = (e) => {
    const id = e.target.id || e.target.name;
    const isSelectMultiple = Array.isArray(e.target.value);
    const value = isSelectMultiple ? e.target.value : e.target.value;

    switch (id) {
      case "inspectoresAsignados":
        setSelectedInspectores(value);
        break;
      case "administrativosAsignados":
        setSelectedAdministrativos(value);
        break;
      case "empresa":
        setSelectedEmpresa(value);
        const empresaSeleccionada = empresas.find(emp => emp.id === value);
        setData(prev => ({
          ...prev,
          empresaId: value,
          empresaNombre: empresaSeleccionada?.nombre || ''
        }));
        break;
      case "provincia":
      case "comunidad":
        if (adminProvincias.includes(value) || adminComunidades.includes(value)) {
          setData(prev => ({ ...prev, [id]: value }));
        }
        break;
      default:
        setData(prev => ({ ...prev, [id]: value }));
    }
  };

  /**
 * Maneja la actualización de la tienda.
 *
 * @param {Object} e - Evento de envío del formulario.
 */
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (!currentService) return;

      const docRef = doc(db, "servicios", currentService.id, "tiendas", tiendaId);

      await setDoc(docRef, {
        ...data,
        inspectores: selectedInspectores,
        inspectoresAsignados: selectedInspectores,
        administrativos: selectedAdministrativos,
        administrativosAsignados: selectedAdministrativos,
        empresaId: selectedEmpresa,
        empresaNombre: empresas.find(emp => emp.id === selectedEmpresa)?.nombre || '',
        timeStamp: serverTimestamp(),
      });

      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };


  const getInspectorNameById = (id) => {
    const inspector = inspectoresAsignados.find(ins => ins.id === id);
    return inspector ? inspector.apellido ? `${inspector.nombre} ${inspector.apellido}` : inspector.nombre : '';
  };

  const getAdministrativoNameById = (id) => {
    const administrativo = administrativosAsignados.find(adm => adm.id === id);
    return administrativo ? administrativo.apellido ? `${administrativo.nombre} ${administrativo.apellido}` : administrativo.nombre : '';
  };


  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(-1);
            }}
            style={{ marginLeft: '20px' }}
          >
            Volver
          </Button>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                newFile
                  ? URL.createObjectURL(newFile)
                  : currentImageUrl || "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt="Imagen de la Tienda"
            />
            <label htmlFor="file">
              <div className='upload-label'>
                <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
              </div>
            </label>
          </div>
          <div className="right">
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />

              </div>
              <div className="formInput">
                <FormControl sx={{ m: 1, width: 200 }}>
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    id="empresa"
                    name="empresa"
                    value={selectedEmpresa}
                    onChange={handleInput}
                    label="Empresa"
                  >
                    {empresas.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id}>
                        {empresa.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="inputPairs">
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    {input.id === "inspectoresAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="inspectoresAsignados-label">{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name="inspectoresAsignados"
                          multiple
                          value={selectedInspectores || []} // Asegurarse de que siempre sea un array
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-inspectores" label="Inspectores" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {(selected || []).map((id) => (
                                <Chip key={id} label={getInspectorNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {inspectoresAsignados.map((inspector) => (
                            <MenuItem key={inspector.id} value={inspector.id}>
                              {inspector.nombre} {inspector.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {input.id === "administrativosAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="administrativosAsignados-label">{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name="administrativosAsignados"
                          multiple
                          value={selectedAdministrativos}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-administrativos" label="Administrativos" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((id) => (
                                <Chip key={id} label={getAdministrativoNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {administrativosAsignados.map((administrativo) => (
                            <MenuItem key={administrativo.id} value={administrativo.id}>
                              {administrativo.nombre} {administrativo.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {['comunidad', 'provincia'].includes(input.id) && input.type === 'dropdown' && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id={`${input.id}-label`}>{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name={input.id}
                          value={data[input.id] || ''} // Asegurarse de que siempre haya un valor
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id={`select-${input.id}`} label={input.label} />}
                        >
                          {input.id === 'comunidad' ? (
                            adminComunidades.map((comunidad) => (
                              <MenuItem key={comunidad} value={comunidad}>
                                {comunidad}
                              </MenuItem>
                            ))
                          ) : (
                            adminProvincias.map((provincia) => (
                              <MenuItem key={provincia} value={provincia}>
                                {provincia}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    )}
                    {input.id !== "inspectoresAsignados" &&
                      input.id !== "administrativosAsignados" &&
                      input.type !== "dropdown" && (
                        <input
                          id={input.id}
                          type={input.type}
                          placeholder={input.placeholder}
                          value={data[input.id]}
                          onChange={handleInput}
                        />
                      )}
                  </div>
                ))}
              </div>
              <button disabled={per !== null && per < 100} type="submit">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div >
  );
}

export default UpdateTienda;

