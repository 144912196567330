import React, { useState, useEffect } from 'react';
import CreateTramo from './CreateTramo';
import { eliminarFilaCompleta, obtenerTurnoPorId } from './FirestoreCuadrantes';
import { Button } from '@mui/material';
import ActualizarTurno from './Actualizarturno';
import './GridView.css';

const GridView = ({ events, cuadranteId, currentService, servicioId, onMesChange }) => {
  const [mostrarFormularioTramo, setMostrarFormularioTramo] = useState(false);
  const [filaAEliminar, setFilaAEliminar] = useState(null);
  const [mesSeleccionado, setMesSeleccionado] = useState(new Date());
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(null);
  const [processedEvents, setProcessedEvents] = useState([]);
  const [turnosDetalle, setTurnosDetalle] = useState({});

  // Procesar eventos del calendario y obtener detalles adicionales
  useEffect(() => {
    const fetchTurnosDetails = async () => {
      if (!events || !Array.isArray(events)) return;

      const detallesPorId = {};
      for (const event of events) {
        try {
          const turnoDetalle = await obtenerTurnoPorId(cuadranteId, event.id, currentService);
          detallesPorId[event.id] = turnoDetalle;
        } catch (error) {
          console.error('Error obteniendo detalles del turno:', error);
        }
      }

      setTurnosDetalle(detallesPorId);

      const processed = events.map(event => ({
        ...event,
        fecha: event.start.toISOString().split('T')[0],
        inicioTurno: event.start.getHours().toString().padStart(2, '0') + ':' + 
                     event.start.getMinutes().toString().padStart(2, '0'),
        finTurno: event.end.getHours().toString().padStart(2, '0') + ':' + 
                  event.end.getMinutes().toString().padStart(2, '0'),
        turno: detallesPorId[event.id]?.turno || '',
        vigilante: detallesPorId[event.id]?.vigilante,
      }));

      setProcessedEvents(processed);

    };

    fetchTurnosDetails();
  }, [events, cuadranteId, currentService]);

  // Obtener vigilantes únicos con sus datos
  const uniqueVigilantes = [...new Set(processedEvents.map(event => event.title))].map(vigilante => {
    const event = processedEvents.find(e => e.title === vigilante);
    const turnoDetalle = event ? turnosDetalle[event.id] : null;
    console.log(event)
    return {
      id: event?.id,
      nombre: vigilante,
      persona: event?.vigilante || '',
      turno: turnoDetalle?.turno || 'Sin turno'
    };
  });

  console.log(uniqueVigilantes)
  
  const obtenerHoraInicioFin = (vigilante, dia) => {
    const diaFormateado = dia.toString().padStart(2, '0');
    const mesFormateado = (mesSeleccionado.getMonth() + 1).toString().padStart(2, '0');
    const fechaBuscada = `${mesSeleccionado.getFullYear()}-${mesFormateado}-${diaFormateado}`;

    const evento = processedEvents.find(event => 
      event.title === vigilante && 
      event.fecha === fechaBuscada
    );

    if (!evento) return null;
    return `${evento.inicioTurno}-${evento.finTurno}`;
  };

  const calcularTotalHoras = (vigilante) => {
    const eventosVigilante = processedEvents.filter(event => {
      const eventDate = new Date(event.start);
      return event.title === vigilante && 
             eventDate.getMonth() === mesSeleccionado.getMonth();
    });

    let totalHoras = 0;
    eventosVigilante.forEach(event => {
      const horasInicio = event.start.getHours();
      const minutosInicio = event.start.getMinutes();
      const horasFin = event.end.getHours();
      const minutosFin = event.end.getMinutes();

      let horas;
      if (horasFin >= horasInicio) {
        horas = horasFin - horasInicio + (minutosFin - minutosInicio) / 60;
      } else {
        horas = (24 - horasInicio) + horasFin + (minutosFin - minutosInicio) / 60;
      }

      totalHoras += horas;
    });

    return totalHoras.toFixed(2);
  };

  // Resto de funciones auxiliares
  const handleMostrarFormularioTramo = () => {
    setMostrarFormularioTramo(true);
  };

  const handleEliminarFila = (vigilante) => {
    const eventosAEliminar = processedEvents
      .filter(event => event.title === vigilante)
      .map(event => event.id);
    setFilaAEliminar(eventosAEliminar);
  };

  const handleConfirmarEliminarFila = async () => {
    try {
      for (const eventId of filaAEliminar) {
        await eliminarFilaCompleta(eventId, currentService, cuadranteId);
      }
      setFilaAEliminar(null);
    } catch (error) {
      console.error('Error al eliminar la fila:', error);
    }
  };

  const handleMesChange = (e) => {
    const nuevaFecha = new Date(e.target.value);
    setMesSeleccionado(nuevaFecha);
    if (onMesChange) {
      onMesChange(nuevaFecha);
    }
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const daysOfMonth = [];
  const ultimoDiaMes = getDaysInMonth(mesSeleccionado);
  for (let i = 1; i <= ultimoDiaMes; i++) {
    const date = new Date(mesSeleccionado.getFullYear(), mesSeleccionado.getMonth(), i);
    daysOfMonth.push(date.getDate() + " " + date.toLocaleString('default', { month: 'short' }));
  }

  const handleEditarCasilla = (vigilante, dia) => {
    const diaFormateado = dia.toString().padStart(2, '0');
    const mesFormateado = (mesSeleccionado.getMonth() + 1).toString().padStart(2, '0');
    const fechaBuscada = `${mesSeleccionado.getFullYear()}-${mesFormateado}-${diaFormateado}`;

    const evento = processedEvents.find(event =>
      event.title === vigilante &&
      event.fecha === fechaBuscada
    );

    if (evento) {
      setTurnoSeleccionado(evento.id);
    }
  };

  // Renderizado del componente
  return (
    <div className="grid-view">
      <div className="select-month">
        <label htmlFor="mesSeleccionado">Seleccione el mes:</label>
        <select
          id="mesSeleccionado"
          value={`${mesSeleccionado.getFullYear()}-${(mesSeleccionado.getMonth() + 1).toString().padStart(2, '0')}-01`}
          onChange={handleMesChange}
        >
          {[...Array(12)].map((_, i) => {
            const fecha = new Date(2025, i, 1);
            return (
              <option
                key={i}
                value={`2025-${(i + 1).toString().padStart(2, '0')}-01`}
              >
                {fecha.toLocaleString('default', { month: 'long' })}
              </option>
            );
          })}
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Vigilantes</th>
            <th>Turno</th>
            {daysOfMonth.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
            <th>Total Horas</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {uniqueVigilantes.map((vigilante, vIndex) => (
            <tr key={vIndex}>
              <td>{vigilante.persona}</td>
              <td>{vigilante.turno}</td>
              {daysOfMonth.map((_, dIndex) => (
                <td
                  key={`${vIndex}-${dIndex}`}
                  onClick={() => handleEditarCasilla(vigilante.nombre, dIndex + 1)}
                  className={obtenerHoraInicioFin(vigilante.nombre, dIndex + 1) ? 'has-turno' : ''}
                >
                  {obtenerHoraInicioFin(vigilante.nombre, dIndex + 1)}
                </td>
              ))}
              <td>{calcularTotalHoras(vigilante.nombre)}</td>
              <td>
                <Button 
                  variant="contained" 
                  color="error" 
                  onClick={() => handleEliminarFila(vigilante.nombre)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modales */}
      {turnoSeleccionado && (
        <div className="modal">
          <div className="modal-content">
            <ActualizarTurno
              cuadranteId={cuadranteId}
              turnoId={turnoSeleccionado}
              currentService={currentService}
              onUpdateComplete={() => setTurnoSeleccionado(null)}
              servicioId={servicioId}
              onClose={() => setTurnoSeleccionado(null)}
            />
          </div>
        </div>
      )}

      {servicioId && (
        <Button variant="contained" color="primary" onClick={handleMostrarFormularioTramo}>
          Crear Tramo
        </Button>
      )}

      {mostrarFormularioTramo && (
        <div className="modal">
          <div className="modal-content">
            <CreateTramo
              onClose={() => setMostrarFormularioTramo(false)}
              cuadranteId={cuadranteId}
              currentService={currentService}
              servicioId={servicioId}
            />
          </div>
        </div>
      )}

      {filaAEliminar !== null && (
        <div className="modal">
          <div className="modal-content">
            <p>¿Estás seguro de que deseas eliminar esta fila?</p>
            <div className="modal-buttons">
              <Button variant="contained" color="primary" onClick={handleConfirmarEliminarFila}>
                Confirmar
              </Button>
              <Button variant="contained" onClick={() => setFilaAEliminar(null)}>
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridView;