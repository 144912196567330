import React, { useEffect, useState, useContext } from "react";
import { collection, getDocs, getDoc, doc, query, where, setDoc } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import { db } from "../../firebase";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { tiendasColumns } from "../../datatablesource";
import { AuthContext } from "../../context/AuthContext";
import customLocaleText from './LocaleText.js'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import ArrayCellRenderer from "../../ArrayCellRenderer.js";
import Button from '@mui/material/Button';

const DatatableTiendasCliente = () => {
  const { clienteId, nombreCliente } = useParams();
  const [tiendas, setTiendas] = useState([]);
  const [filter, setFilter] = useState("");
  const [clienteImg, setClienteImg] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [recoveredItems, setRecoveredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [emails, setEmails] = useState({});

  const { currentService, adminData } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (currentService.id) {
        try {
          console.log("Tipos de usuario:", adminData.tipoUsuario);
          console.log("¿Es administrador?:", adminData.tipoUsuario?.includes("administrador"));
          let tiendasQuery;
          
          // Check if user has admin role
          if (adminData.tipoUsuario?.includes("administrador")) {
            // Admin can see all stores for the client without filtering by assigned stores
            console.log("Realizando consulta de administrador");
            // Primero obtengamos todas las tiendas sin filtro
            const todasLasTiendasRef = collection(db, "servicios", currentService.id, "tiendas");
            tiendasQuery = query(
              todasLasTiendasRef,
              where("cliente", "==", clienteId)
            );
            
            const tiendasSnapshot = await getDocs(tiendasQuery);
            const tiendasData = tiendasSnapshot.docs.map(doc => ({ 
              id: doc.id, 
              ...doc.data() 
            }));
            console.log("Tiendas encontradas:", tiendasData);
            setTiendas(tiendasData);
          } else {
            // Non-admin users only see their assigned stores
            const tiendasIds = adminData.tiendas;
            const batchSize = 30;
            
            for (let i = 0; i < tiendasIds.length; i += batchSize) {
              const batchIds = tiendasIds.slice(i, i + batchSize);
              tiendasQuery = query(
                collection(db, "servicios", currentService.id, "tiendas"),
                where("__name__", "in", batchIds),
                where("cliente", "==", clienteId)
              );

              const tiendasSnapshot = await getDocs(tiendasQuery);
              const tiendasData = tiendasSnapshot.docs.map(doc => ({ 
                id: doc.id, 
                ...doc.data() 
              }));
              setTiendas(prevTiendas => [...prevTiendas, ...tiendasData]);
            }
          }

          // Fetch client image
          const clienteDoc = doc(db, "servicios", currentService.id, "clientes", clienteId);
          const clienteSnap = await getDoc(clienteDoc);
          if (clienteSnap.exists()) {
            setClienteImg(clienteSnap.data().img || "");
          }
        } catch (error) {
          console.error("Error al obtener las tiendas:", error);
        }
      } else {
        console.error("Servicio no definido en el contexto.");
      }
    };

    fetchData();
  }, [clienteId, adminData, currentService.id]);

  // Rest of the component remains the same...
  useEffect(() => {
    tiendas.forEach((tienda) => {
      if (tienda.administrativos || tienda.inspectores) {
        const ids = [...(tienda.administrativos || []), ...(tienda.inspectores || [])];
        ids.forEach(async (id) => {
          if (!emails[id]) {
            const email = await getEmailForId(id);
            setEmails((prevEmails) => ({ ...prevEmails, [id]: email }));
          }
        });
      }
    });
  }, [tiendas]);

  const getEmailForId = async (id) => {
    try {
      if (currentService && adminData) {
        const adminDocRef = doc(db, "servicios", currentService.id, "admins", id);
        const adminDocSnap = await getDoc(adminDocRef);

        if (adminDocSnap.exists()) {
          const adminData = adminDocSnap.data();
          return adminData.correo;
        }
      }
    }
    catch (error) {
      console.error("Error al obtener datos de administrador:", error);
    }
    return "";
  };

  const handleDelete = async (idsToDelete) => {
    if (!currentService.id) {
      console.error("Servicio no definido en el contexto.");
      return;
    }

    const confirmDelete = window.confirm("¿Estás seguro de que deseas borrar los servicios seleccionados?");
    if (!confirmDelete) {
      return;
    }

    const idsArray = Array.isArray(idsToDelete) ? idsToDelete : [idsToDelete];

    const promises = idsArray.map(async (id) => {
      const tiendaRef = doc(db, "servicios", currentService.id, "tiendas", id);
      const tiendaDoc = await getDoc(tiendaRef);

      if (tiendaDoc.exists()) {
        const newStatus = tiendaDoc.data().borrado ? false : true;
        await setDoc(tiendaRef, { borrado: newStatus }, { merge: true });
        return { id, borrado: newStatus };
      }
      return null;
    });

    try {
      const results = await Promise.all(promises);
      const updatedTiendas = results.filter(result => result !== null);
      setTiendas(prevTiendas =>
        prevTiendas.map(tienda =>
          updatedTiendas.find(updated => updated.id === tienda.id)
            ? { ...tienda, borrado: updatedTiendas.find(updated => updated.id === tienda.id).borrado }
            : tienda
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Acción",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/tiendas/update/${encodeURIComponent(params.row.id)}`} style={{ textDecoration: "none" }}>
              <div className="actualizarButton">Actualizar</div>
            </Link>
            {params.row.borrado ? (
              <button className="recoverButton"
                onClick={() => handleDelete(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
              >
                Recuperar
              </button>
            ) : (
              <DeleteIcon
                onClick={() => handleDelete(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
                className="deleteIcon" />
            )}
          </div>
        );
      },
    },
  ];

  const onSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };

  const filteredData = tiendas.filter((row) => {
    const identifierValue = row.numTienda.toLowerCase();
    return (
      identifierValue.includes(filter.toLowerCase()) &&
      (showDeleted ? row.borrado || recoveredItems.includes(row.id) : !row.borrado)
    );
  });

  const tiendasColumnsWithEmails = tiendasColumns.map((column) => {
    if (column.field === "administrativos" || column.field === "inspectores") {
      return {
        ...column,
        renderCell: (params) => {
          const ids = params.value || [];
          const emailValues = ids.map((id) => emails[id]);
          return <ArrayCellRenderer values={emailValues} />;
        },
      };
    }
    return column;
  });

  const columns = tiendasColumnsWithEmails.concat(actionColumn);

  return (
    <div className="datatable">
      <div className="datatableTitle datatableTitle-tiendas">
        <div className="headTiendas">
          {clienteImg ? (
            <img src={clienteImg} alt="Logo" className="clienteImg" />
          ) : (
            <StoreMallDirectoryRoundedIcon className="clienteImg" style={{ color: '#fff', fontSize: 40 }} />
          )}
          <h2 className="title-registro-tiendas">
            Servicios de {nombreCliente}
          </h2>
        </div>
        <div className="rightActions">
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" />
          </div>
          <Link
            to={`/clientes/${encodeURIComponent(clienteId)}/newClienteTienda/${encodeURIComponent(nombreCliente)}`}
            className="link link-right"
          >
            Nuevo servicio
          </Link>
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={showDeleted}
            onChange={handleShowDeletedChange}
            color="primary"
          />
        }
        label="Mostrar borrados"
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Volver
      </Button>
      <DataGridPro
        className="datagrid"
        rows={filteredData}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        pageSize={100}
        rowsPerPageOptions={[9]}
        checkboxSelection
        onRowSelectionModelChange={onSelectionModelChange}
        localeText={customLocaleText}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </div>
  );
};

export default DatatableTiendasCliente;