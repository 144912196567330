import "./update.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  collection,
  doc,
  serverTimestamp,
  getDocs,
  where,
  query,
  updateDoc
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { fetchTiendas, fetchClientes } from '../new/utils/FirebaseUtils';
import { ImageUploader, ServiceDropdown, TiendaInput } from '../new/utils/NewUtils'
import { uploadErrorLogToFirestore } from "../../utils/logUtils";
import { fetchClientesPorAdministrativo, fetchClientesPorInspector, fetchTiendasPorCampoCliente } from "../../components/admin/firestoreService"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';

/**
 * Componente para la actualización de un vigilante de seguridad.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.inputs - Datos de entrada para el formulario.
 * @param {string} props.title - Título del componente.
 * @returns {JSX.Element} - Componente Update.
 */
const Update = ({ inputs, title }) => {

  const [file, setFile] = useState("");
  const [data, setData] = useState({ tiendas: [] });
  const [per, setPerc] = useState(null);
  const [clientes, setClientes] = useState([]); // Estado para almacenar la lista de clientes
  const [tiendasCliente, setTiendasCliente] = useState([]); // Estado para las tiendas del cliente seleccionado
  const [tiendas, setTiendas] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(data.tiendas.map(tienda => tienda.numero.toString()));
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
  const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");

  const navigate = useNavigate();
  const { currentUser, currentService, adminData } = useContext(AuthContext);
  const { collapsed } = useSidebarState();
  const { tipVS } = useParams();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchTiendas(currentUser.email);
        setTiendas(list);

        // Obtener la lista de clientes basada en el tipo de usuario y el servicio actual
        if (currentUser && currentService && adminData) {
          if (adminData?.tipoUsuario?.includes('administrativo')) {
            fetchClientesPorAdministrativo(adminData.id, currentService)
              .then(clientesFiltrados => {
                setClientes(clientesFiltrados);
              })
              .catch(console.error);
          } else if (adminData.tipoUsuario?.includes('inspector')) {
            fetchClientesPorInspector(adminData.id, currentService)
              .then(clientesFiltrados => {
                setClientes(clientesFiltrados);
              })
              .catch(console.error);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [currentUser, currentService, adminData]);

  useEffect(() => {
    const fetchVigilanteData = async () => {
      try {
        const serviciosSnapshot = await getDocs(
          query(
            collection(db, "servicios"),
            where("admins", "array-contains", currentUser.email)
          )
        );
        if (!serviciosSnapshot.empty) {
          const servicioDoc = serviciosSnapshot.docs[0];
          const vigilantesSnapshot = await getDocs(
            query(
              collection(servicioDoc.ref, "vigilantes"),
              where("tipVS", "==", tipVS)
            )
          );
          if (!vigilantesSnapshot.empty) {
            const vigilanteData = vigilantesSnapshot.docs[0].data();
            setData(vigilanteData);

            // Establecer las tiendas seleccionadas
            setSelectedOptions(vigilanteData.tiendas?.map(tienda => tienda.numero.toString()) || []);

            // Obtener y establecer los clientes de las tiendas
            const clientesDelVigilante = new Set();
            vigilanteData.tiendas?.forEach(tienda => {
              const tiendaCompleta = tiendas.find(t => t.numTienda === tienda.numero);
              if (tiendaCompleta) {
                // Asegurarse de que cliente sea siempre un array
                const clientesArray = Array.isArray(tiendaCompleta.cliente)
                  ? tiendaCompleta.cliente
                  : tiendaCompleta.cliente
                    ? [tiendaCompleta.cliente]
                    : [];

                clientesArray.forEach(c => clientesDelVigilante.add(c));
              }
            });
            setSelectedClientes(Array.from(clientesDelVigilante));

            // Actualizar las tiendas disponibles para los clientes seleccionados
            const tiendasFiltradas = tiendas.filter(tienda => {
              // Asegurarse de que cliente sea siempre un array
              const clientesArray = Array.isArray(tienda.cliente)
                ? tienda.cliente
                : tienda.cliente
                  ? [tienda.cliente]
                  : [];

              return clientesArray.some(c => clientesDelVigilante.has(c));
            });
            setTiendasCliente(tiendasFiltradas);
          }
        }
      } catch (err) {
        console.error("Error fetching vigilante data:", err);
      }
    };

    if (currentUser && tipVS && tiendas.length > 0) {
      fetchVigilanteData();
    }
  }, [currentUser, tipVS, tiendas]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          uploadErrorLogToFirestore(error)
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);


  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  // Manejador de cambio de cliente seleccionado
  const handleClienteChange = (e) => {
    const clientesSeleccionados = e.target.value;
    if (clientesSeleccionados.includes('all')) {
      setIsAllClientesSelected(!isAllClientesSelected);
      setSelectedClientes(isAllClientesSelected ? [] : clientes.map(c => c.id));
      // Actualizar también las tiendas según la selección
    } else {
      setIsAllClientesSelected(clientesSeleccionados.length === clientes.length);
      setSelectedClientes(clientesSeleccionados);
      // Filtrar y actualizar las tiendas basadas en los clientes seleccionados
    }

    // Filter the stores based on the selected clients
    const filteredTiendas = tiendas.filter((tienda) => {
      // Check if any of the selected clients match the clients associated with the store
      return clientesSeleccionados.some((selectedCliente) =>
        tienda?.cliente?.includes(selectedCliente)
      );
    });

    // Update the list of stores based on the selected clients
    setTiendasCliente(filteredTiendas);
  }

  const handleCheckboxChange = (event) => {
    const selectedValues = event.target.value;

    if (selectedValues.includes('allServices')) {
      setIsAllTiendasSelected(!isAllTiendasSelected);
      const allTiendas = isAllTiendasSelected ? [] : tiendasCliente.map(t => ({
        numero: t.numTienda
      }));
      setSelectedOptions(isAllTiendasSelected ? [] : tiendasCliente.map(t => t.numTienda.toString()));
      setData(prev => ({ ...prev, tiendas: allTiendas }));
    } else {
      setIsAllTiendasSelected(selectedValues.length === tiendasCliente.length);
      setSelectedOptions(selectedValues);

      // Convertir las tiendas seleccionadas al formato correcto
      const selectedTiendas = tiendasCliente
        .filter(tienda => selectedValues.includes(tienda.numTienda.toString()))
        .map(tienda => ({
          numero: tienda.numTienda
        }));

      setData(prev => ({ ...prev, tiendas: selectedTiendas }));
    }
  };


  /**
 * Maneja el registro de la nueva entrada.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();

    // Validaciones de tiendas...
    const storeNumbers = data.tiendas.map((tienda) => tienda.numero);
    const uniqueStoreNumbers = new Set(storeNumbers);
    if (storeNumbers.length !== uniqueStoreNumbers.size) {
      window.alert("Registro fallido: Hay tiendas duplicadas");
      return;
    }

    if (storeNumbers.length === 0) {
      window.alert("Registro fallido: No se ha agregado ninguna tienda");
      return;
    }

    const validStoreNumbers = tiendas.map((tienda) => tienda.numTienda);
    const selectedStoreNumbers = data.tiendas.map((tienda) => tienda.numero);
    const isValidStore = selectedStoreNumbers.every((number) =>
      validStoreNumbers.includes(number)
    );
    if (!isValidStore) {
      window.alert(
        "Registro fallido: El identificador de servicio seleccionado no es válido"
      );
      return;
    }

    try {
      if (!currentUser?.email || !currentService?.id || !tipVS) {
        console.error("Faltan datos necesarios para la actualización");
        return;
      }

      // Obtener referencia al documento del vigilante directamente
      const vigilantesRef = collection(db, "servicios", currentService.id, "vigilantes");
      const vigilanteQuery = query(vigilantesRef, where("tipVS", "==", tipVS));
      const vigilanteSnapshot = await getDocs(vigilanteQuery);

      if (vigilanteSnapshot.empty) {
        console.error("No se encontró el vigilante para actualizar");
        return;
      }

      const vigilanteDoc = vigilanteSnapshot.docs[0];

      // Preparar datos para actualización
      const updateData = {
        ...data,
        borrado: false,
        timeStamp: serverTimestamp(),
      };

      // Si persona está vacío, eliminarlo del objeto
      if (!updateData.persona) {
        delete updateData.persona;
      }

      // Realizar la actualización
      await updateDoc(vigilanteDoc.ref, updateData);
      console.log("Vigilante actualizado correctamente");
      setUpdateMessage("Vigilante actualizado correctamente");

      // Esperar un momento para mostrar el mensaje de éxito
      setTimeout(() => {
        navigate(-1);
      }, 1500);


    } catch (err) {
      console.error("Error en la actualización:", err);
      uploadErrorLogToFirestore("error actualizando vigilante: " + err.message);

      if (err.message.includes("password")) {
        window.alert("Actualización fallida: La contraseña debe tener al menos 6 caracteres");
      } else if (err.message.includes("email-already-in-use")) {
        window.alert("Actualización fallida: El vigilante ya ha sido registrado");
      } else {
        window.alert("Actualización fallida: " + err.message);
      }
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <ImageUploader file={file} setFile={setFile} />
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <div className="inputPairs">
                  <FormControl className="formInput">
                    <InputLabel id="clientes-label">Clientes</InputLabel>
                    <Select
                      labelId="clientes-label"
                      id="clientes"
                      multiple
                      value={selectedClientes}
                      onChange={handleClienteChange}
                      label="Clientes"
                    >
                      <MenuItem value="all">
                        <Checkbox checked={isAllClientesSelected} />
                        <ListItemText primary="Seleccionar todos los clientes" />
                      </MenuItem>
                      {clientes.map((cliente) => (
                        <MenuItem key={cliente.id} value={cliente.id}>
                          {cliente.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="formInput">
                    <InputLabel id="servicios-label">Servicios</InputLabel>
                    <Select
                      labelId="servicios-label"
                      id="servicios"
                      multiple
                      value={selectedOptions}
                      onChange={handleCheckboxChange}
                      label="Servicios"
                    >
                      <MenuItem value="allServices">
                        <Checkbox checked={isAllTiendasSelected} />
                        <ListItemText primary="Seleccionar todos los servicios" />
                      </MenuItem>
                      {tiendasCliente.map((tienda) => (
                        <MenuItem key={tienda.numTienda} value={tienda.numTienda.toString()}>
                          {tienda.numTienda}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </div>
              </div>
              <div className="formInput">
                <label>Nombre:</label>
                <input
                  id="nombre"
                  type="text"
                  placeholder="Nombre"
                  onChange={handleInput}
                  value={data.nombre}  // Agrega este valor para el campo nombre
                  required
                />
              </div>
              <div className="formInput">
                <label>Apellidos:</label>
                <input
                  id="apellidos"
                  type="text"
                  placeholder="Apellidos"
                  onChange={handleInput}
                  value={data.apellidos}  // Agrega este valor para el campo apellidos
                  required
                />
              </div>
              <div className="formInput">
                <label>Persona:</label>
                <input
                  id="persona"
                  type="number"
                  placeholder="Número de persona"
                  onChange={handleInput}
                  value={data.persona || ''}
                />
              </div>
              {/* Agrupa los campos de entrada en pares, excepto nombre y apellidos */}
              <div className="inputPairs">
                {inputs.map((input, index) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      value={data[input.id]}
                      {...(input.id === 'telefono' || input.id === 'email' ? { required: false } : {})}
                      // Deshabilita los inputs correspondientes a tipVS y dni
                      disabled={input.id === "tipVS" || input.id === "dni"}
                    />
                  </div>
                ))}
              </div>
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Actualizar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update;
