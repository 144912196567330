import './style/styles.css'; // Asegúrate de importar tu archivo CSS


export const userInputs = [
  {
    id: "dni",
    label: "Password (obligatorio)",
    type: "text",
    placeholder: "contraseña*",
  },
  {
    id: "tipVS",
    label: "Tip VS (obligatorio)",
    type: "text",
    placeholder: "Número identificación del vigilante*",
  },
  {
    id: "telefono",
    label: "Telefono",
    type: "text",
    placeholder: "Número de teléfono",
  },
  {
    id: "email",
    label: "email",
    type: "email",
    placeholder: "correo electrónico"
  },
  {
    field: "persona",
    headerName: "Persona",
    width: 150,
    renderCell: (params) => {
      if (!params.value) return null;
      return (
        <div style={{
          borderRadius: '10px',
          background: 'rgba(73, 80, 87, 0.27)',
          padding: '5px 10px',
          color: 'black',
          whiteSpace: 'nowrap'
        }}>
          {params.value}
        </div>
      );
    },
  },
];

export const productInputs = [
  {
    id: "numTienda",
    label: "Identificador servicio",
    type: "text",
    placeholder: "tienda",
  },
  {
    id: "localizacion",
    label: "Localización",
    type: "text",
    placeholder: "localización",
  },
  {
    id: "provincia",
    label: "Provincia",
    type: "dropdown",
    placeholder: "provincia",
  },
  {
    id: "comunidad",
    label: "Comunidad",
    type: "dropdown",
    placeholder: "comunidad",
  },
  {
    id: "inspectoresAsignados",
    label: "Inspectores Asignados",
    type: "text",
    placeholder: "inspectores asignados",
  },
  {
    id: "administrativosAsignados",
    label: "Administrativos Asignados",
    type: "text",
    placeholder: "administrativos asignados",
  },
];

export const clientesInputs = [
  {
    id: "nombre",
    label: "Identificador cliente",
    type: "text",
    placeholder: "nombre",
  },
  {
    id: "telefono",
    label: "Telefono",
    type: "number",
    placeholder: "Número de teléfono",
  },
  {
    id: "email",
    label: "email",
    type: "email",
    placeholder: "correo electrónico"
  },
  {
    id: "provincia",
    label: "Provincia",
    type: "dropdown",
    placeholder: "provincia",
  },
  {
    id: "comunidad",
    label: "Comunidad",
    type: "dropdown",
    placeholder: "comunidad",
  },
  {
    id: "inspectoresAsignados",
    label: "Inspectores Asignados",
    type: "dropdown",
    placeholder: "inspectores asignados",
  },
  {
    id: "administrativosAsignados",
    label: "Administrativos Asignados",
    type: "dropdown",
    placeholder: "administrativos asignados",
  },
];
